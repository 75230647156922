import React from "react";


export const JOB_SLUG = {
	'chuyen-vien-doi-soat': {
		title: 'CHUYÊN VIÊN ĐỐI SOÁT (Reconciliation Specialist)',
		content: (
			<div className='px-8 md:px-[256px] py-8'>
				<h1 className='font-bold text-xl text-[#F05A24]'>M&Ocirc; TẢ C&Ocirc;NG VIỆC</h1>
				<br/><h2 className='font-bold'>I.TH&Ocirc;NG TIN CHUNG:</h2>
				<ul>
					<li>Địa điểm l&agrave;m việc: Th&agrave;nh phố Hồ Ch&iacute; Minh</li>
					<li>Ph&ograve;ng: Vận h&agrave;nh &amp; Chăm s&oacute;c kh&aacute;ch h&agrave;ng</li>
				</ul>
				<br/><h2 className='font-bold'>II.MỤC Đ&Iacute;CH:&nbsp;</h2>
				<ul>
					<li>Chịu tr&aacute;ch nhiệm đối chiếu c&aacute;c giao dịch t&agrave;i ch&iacute;nh giữa hệ thống
						v&iacute; điện
						tử của c&ocirc;ng ty v&agrave; c&aacute;c đối t&aacute;c ng&acirc;n h&agrave;ng hoặc b&ecirc;n thứ ba nhằm
						đảm
						bảo t&iacute;nh ch&iacute;nh x&aacute;c, minh bạch v&agrave; đồng nhất trong dữ liệu.&nbsp;</li>
					<li>Đ&oacute;ng vai tr&ograve; quan trọng trong việc đảm bảo d&ograve;ng tiền của c&ocirc;ng ty được quản
						l&yacute; hiệu quả, giảm thiểu sai s&oacute;t v&agrave; rủi ro trong quy tr&igrave;nh xử
						l&yacute; t&agrave;i
						ch&iacute;nh.
					</li>
				</ul>
				<br/><h2 className='font-bold'>III.TR&Aacute;CH NHIỆM:&nbsp;</h2>
				<p className='font-bold'>1. Đối chiếu giao dịch:</p>
				<ul>
					<li>Thực hiện đối chiếu h&agrave;ng ng&agrave;y giữa c&aacute;c giao dịch tr&ecirc;n hệ thống v&iacute; điện
						tử
						với c&aacute;c giao dịch từ ng&acirc;n h&agrave;ng, đối t&aacute;c thanh to&aacute;n hoặc c&aacute;c
						b&ecirc;n
						li&ecirc;n quan.
					</li>
					<li>X&aacute;c minh v&agrave; điều chỉnh c&aacute;c giao dịch kh&ocirc;ng khớp để đảm bảo t&iacute;nh
						ch&iacute;nh x&aacute;c của sổ s&aacute;ch.
					</li>
				</ul>
				<p className='font-bold'>2. Giải quyết sai lệch:</p>
				<ul>
					<li>Điều tra v&agrave; xử l&yacute; c&aacute;c sai lệch, ph&aacute;t hiện v&agrave; khắc phục c&aacute;c lỗi
						trong hệ thống giao dịch.
					</li>
					<li>Li&ecirc;n hệ với ng&acirc;n h&agrave;ng hoặc đối t&aacute;c để x&aacute;c minh v&agrave; giải quyết
						c&aacute;c tranh chấp li&ecirc;n quan đến giao dịch.
					</li>
				</ul>
				<p className='font-bold'>3. Quản l&yacute; dữ liệu v&agrave; b&aacute;o c&aacute;o:</p>
				<ul>
					<li>Chuẩn bị c&aacute;c b&aacute;o c&aacute;o đối chiếu định kỳ, cung cấp cho quản
						l&yacute; v&agrave; c&aacute;c bộ phận li&ecirc;n quan.
					</li>
					<li>Đảm bảo t&iacute;nh ch&iacute;nh x&aacute;c v&agrave; cập nhật kịp thời của c&aacute;c số liệu t&agrave;i
						ch&iacute;nh li&ecirc;n quan đến giao dịch v&iacute; điện tử.
					</li>
				</ul>
				<p className='font-bold'>4. Kiểm so&aacute;t nội bộ:</p>
				<ul>
					<li>Hỗ trợ ph&aacute;t triển v&agrave; cải tiến c&aacute;c quy tr&igrave;nh đối chiếu nhằm n&acirc;ng cao hiệu
						suất v&agrave; độ ch&iacute;nh x&aacute;c.
					</li>
					<li>Đảm bảo tu&acirc;n thủ c&aacute;c quy định ph&aacute;p l&yacute; v&agrave; chuẩn mực kế to&aacute;n của
						c&ocirc;ng ty cũng như c&aacute;c y&ecirc;u cầu của cơ quan quản l&yacute;.
					</li>
				</ul>
				<p className='font-bold'>5. Kiểm so&aacute;t rủi ro:</p>
				<ul>
					<li>Thực hiện c&aacute;c biện ph&aacute;p kiểm so&aacute;t rủi ro li&ecirc;n quan đến việc đối so&aacute;t
						v&agrave; thanh to&aacute;n, đảm bảo tu&acirc;n thủ c&aacute;c quy định về chống rửa tiền (AML)
						v&agrave; quản
						l&yacute; rủi ro t&agrave;i ch&iacute;nh.
					</li>
					<li>Đề xuất c&aacute;c giải ph&aacute;p n&acirc;ng cao quy tr&igrave;nh đối so&aacute;t, đảm bảo t&iacute;nh
						hiệu quả v&agrave; minh bạch của c&aacute;c hoạt động t&agrave;i ch&iacute;nh.
					</li>
				</ul>
				<p className='font-bold'>6. Phối hợp với c&aacute;c bộ phận li&ecirc;n quan:</p>
				<ul>
					<li>L&agrave;m việc chặt chẽ với bộ phận kế to&aacute;n, kỹ thuật v&agrave; đối t&aacute;c thanh to&aacute;n
						để
						đảm bảo c&aacute;c giao dịch t&agrave;i ch&iacute;nh được xử l&yacute; v&agrave; đối chiếu một c&aacute;ch
						ch&iacute;nh x&aacute;c.
					</li>
					<li>Hỗ trợ c&aacute;c bộ phận kh&aacute;c trong việc điều tra c&aacute;c vấn đề li&ecirc;n quan đến
						d&ograve;ng
						tiền.
					</li>
				</ul>
				<br/><h2 className='font-bold'>IV.QUYỀN HẠN:&nbsp;</h2>
				<ul>
					<li>Thực hiện được c&aacute;c c&ocirc;ng t&aacute;c chuy&ecirc;n m&ocirc;n nghiệp vụ theo đ&uacute;ng chức
						tr&aacute;ch
					</li>
					<li>Tham gia đ&oacute;ng g&oacute;p, x&acirc;y dựng kế hoạch ngắn hạn, trung hạn v&agrave; đề xuất c&aacute;c
						phương &aacute;n để quản l&yacute;, thực hiện c&ocirc;ng t&aacute;c thuộc phạm vi phụ tr&aacute;ch
						v&agrave; đ&aacute;nh gi&aacute; hiệu quả thực hiện
					</li>
					<li>Tổ chức, tham gia tổng hợp, ph&acirc;n t&iacute;ch đ&aacute;nh gi&aacute; c&ocirc;ng t&aacute;c
						chuy&ecirc;n
						m&ocirc;n, đề xuất chủ trương v&agrave; c&aacute;c biện ph&aacute;p n&acirc;ng cao hiệu quả.
					</li>
				</ul>
				<br/><h2 className='font-bold'>V.B&Aacute;O C&Aacute;O V&Agrave; UỶ QUYỀN</h2>
				<ol>
					<li>B&aacute;o c&aacute;o:&nbsp;</li>
				</ol>
				<ul>
					<li>Thực hiện b&aacute;o c&aacute;o định kỳ hoặc đột xuất theo y&ecirc;u cầu của cấp Quản l&yacute; trực tiếp
						về
						mọi th&ocirc;ng tin c&oacute; li&ecirc;n quan đến phạm vi tr&aacute;ch nhiệm được giao.
					</li>
				</ul>
				<ol start="2">
					<li>Ủy quyền:&nbsp;</li>
				</ol>
				<ul>
					<li>Kh&ocirc;ng</li>
				</ul>
				<br/><h2 className='font-bold'>VI.ĐIỀU KIỆN L&Agrave;M VIỆC&nbsp;</h2>
				<ul>
					<li>Thời gian l&agrave;m việc: Giờ h&agrave;nh ch&iacute;nh: Từ 8h00 đến 17h00</li>
				</ul>
				<ul>
					<li>Thời gian nghỉ h&agrave;ng tuần: Nghỉ chiều thứ 7 v&agrave; Ng&agrave;y Chủ nhật</li>
				</ul>
				<ul>
					<li>Trang thiết bị l&agrave;m việc được cung cấp (V&iacute; dụ: Laptop, PC, sim điện thoại,&hellip;): Laptop
					</li>
				</ul>
				<br/><h2 className='font-bold'>VII.TI&Ecirc;U CHUẨN TUYỂN DỤNG</h2>
				<p className='font-bold'>1. TR&Igrave;NH ĐỘ, KIẾN THỨC:</p>
				<ul>
					<li>Tr&igrave;nh độ học vấn tối thiểu: Đại học</li>
					<li>Tr&igrave;nh độ chuy&ecirc;n m&ocirc;n: Tốt nghiệp đại học chuy&ecirc;n ng&agrave;nh t&agrave;i
						ch&iacute;nh, kế to&aacute;n hoặc c&aacute;c lĩnh vực li&ecirc;n quan.
					</li>
					<li>Kiến thức li&ecirc;n quan: Hiểu biết s&acirc;u về lĩnh vực c&ocirc;ng nghệ t&agrave;i ch&iacute;nh, đặc
						biệt
						l&agrave; c&aacute;c sản phẩm v&iacute; điện tử v&agrave; dịch vụ t&agrave;i ch&iacute;nh số
					</li>
				</ul>
				<p className='font-bold'>2. KỸ NĂNG, KHẢ NĂNG:</p>
				<ul>
					<li>Ngoại ngữ: C&oacute; thể đọc hiểu tiếng Anh chuy&ecirc;n ng&agrave;nh</li>
					<li>Tin học: Th&agrave;nh thạo tin học Văn Ph&ograve;ng</li>
					<li>Kỹ năng li&ecirc;n quan:&nbsp;</li>
					<ul>
						<li>Kỹ năng ph&acirc;n t&iacute;ch, giải quyết vấn đề tốt v&agrave; ch&uacute; trọng đến chi tiết.</li>
						<li>Khả năng l&agrave;m việc độc lập v&agrave; phối hợp nh&oacute;m.</li>
						<li>Kỹ năng giao tiếp v&agrave; xử l&yacute; xung đột tốt.</li>
					</ul>
				</ul>
				<ul>
					<li>Khả năng l&agrave;m việc c&aacute; nh&acirc;n:</li>
					<ul>
						<li>Ch&iacute;nh trực.</li>
						<li>Tỉ mỉ, cẩn thận, chi tiết</li>
						<li>C&oacute; tinh thần tr&aacute;ch nhiệm.</li>
						<li>Sẵn s&agrave;ng đi c&ocirc;ng t&aacute;c</li>
					</ul>
				</ul>
				<p className='font-bold'>3. Y&Ecirc;U CẦU KH&Aacute;C:</p>
				<ul>
					<li>Kinh nghiệm c&ocirc;ng t&aacute;c:</li>
					<ul>
						<li>&Iacute;t nhất 2-3 năm kinh nghiệm trong lĩnh vực đối chiếu t&agrave;i ch&iacute;nh, ưu ti&ecirc;n ứng
							vi&ecirc;n c&oacute; kinh nghiệm trong ng&agrave;nh v&iacute; điện tử hoặc fintech.
						</li>
						<li>Kinh nghiệm l&agrave;m việc với c&aacute;c hệ thống thanh to&aacute;n điện tử, phần mềm kế to&aacute;n
							v&agrave; quản l&yacute; t&agrave;i ch&iacute;nh
						</li>
					</ul>
				</ul>
				<ul>
					<li>Giới t&iacute;nh: Kh&ocirc;ng ph&acirc;n biệt</li>
					<li>Độ tuổi: Kh&ocirc;ng giới hạn</li>
				</ul>
				<br/><h2 className='font-bold text-[#F05A24]'>C&Aacute;CH THỨC ỨNG TUYỂN:</h2>
				<p>Ứng vi&ecirc;n nộp hồ sơ trực tuyến bằng c&aacute;ch gửi th&ocirc;ng tin CV đến địa chỉ email <a
					className='underline text-primary'
					href="mailto:tuyendung@goopay.vn">tuyendung@goopay.vn</a> .<br/><br/></p>
			</div>
		)
	},
	'chuyen-vien-cham-soc-khach-hang': {
		title: 'CHUYÊN VIÊN CHĂM SÓC KHÁCH HÀNG (Customer Service Specialist)',
		content: (
			<div className='px-8 md:px-[256px] py-8'>
				<br/><h1 className='font-bold text-xl text-[#F05A24]'>M&Ocirc; TẢ C&Ocirc;NG VIỆC</h1>
				<br/><h2 className='font-bold'>I.TH&Ocirc;NG TIN CHUNG:</h2>
				<ul>
					<li>Địa điểm l&agrave;m việc: Th&agrave;nh phố Hồ Ch&iacute; Minh</li>
					<li>Ph&ograve;ng: Vận h&agrave;nh &amp; Chăm s&oacute;c kh&aacute;ch h&agrave;ng</li>
				</ul>
				<br/><h2 className='font-bold'>II.MỤC Đ&Iacute;CH:&nbsp;</h2>
				<ul>
					<li>Đ&oacute;ng vai tr&ograve; quan trọng trong việc n&acirc;ng cao trải nghiệm kh&aacute;ch h&agrave;ng
						v&agrave; giữ ch&acirc;n người d&ugrave;ng của v&iacute; điện tử.
					</li>
				</ul>
				<br/><h2 className='font-bold'>III.TR&Aacute;CH NHIỆM:&nbsp;</h2>
				<p className='font-bold'>1. Tư vấn v&agrave; giải đ&aacute;p thắc mắc của kh&aacute;ch h&agrave;ng:</p>
				<ul>
					<li>Tiếp nhận v&agrave; xử l&yacute; c&aacute;c y&ecirc;u cầu, thắc mắc từ kh&aacute;ch h&agrave;ng qua
						c&aacute;c k&ecirc;nh như hotline, email, chat, hoặc mạng x&atilde; hội.
					</li>
					<li>Cung cấp th&ocirc;ng tin chi tiết về sản phẩm, dịch vụ của v&iacute; điện tử, hướng dẫn kh&aacute;ch
						h&agrave;ng c&aacute;ch sử dụng v&iacute;, nạp tiền, r&uacute;t tiền v&agrave; c&aacute;c t&iacute;nh năng
						kh&aacute;c.
					</li>
					<li>Hỗ trợ kh&aacute;ch h&agrave;ng trong việc xử l&yacute; c&aacute;c vấn đề ph&aacute;t sinh như giao dịch
						thất bại, sai s&oacute;t t&agrave;i khoản, hoặc c&aacute;c vấn đề li&ecirc;n quan đến t&agrave;i
						ch&iacute;nh.
					</li>
				</ul>
				<p className='font-bold'>2. Giải quyết khiếu nại:</p>
				<ul>
					<li>Tiếp nhận v&agrave; xử l&yacute; c&aacute;c khiếu nại từ kh&aacute;ch h&agrave;ng li&ecirc;n quan đến chất
						lượng dịch vụ, thời gian xử l&yacute; giao dịch, hoặc c&aacute;c vấn đề ph&aacute;t sinh trong
						qu&aacute; tr&igrave;nh sử dụng v&iacute; điện tử.
					</li>
					<li>Phối hợp với c&aacute;c bộ phận li&ecirc;n quan (Ph&aacute;t triển sản phẩm, Vận h&agrave;nh, T&agrave;i
						ch&iacute;nh &ndash; Kế to&aacute;n, Ph&aacute;p l&yacute;,&hellip;) để đảm bảo c&aacute;c khiếu nại của
						kh&aacute;ch h&agrave;ng được giải quyết đ&uacute;ng c&aacute;ch v&agrave; nhanh ch&oacute;ng.
					</li>
				</ul>
				<p className='font-bold'>3. Chăm s&oacute;c kh&aacute;ch h&agrave;ng định kỳ:</p>
				<ul>
					<li>Duy tr&igrave; mối quan hệ tốt với kh&aacute;ch h&agrave;ng th&ocirc;ng qua việc theo d&otilde;i
						v&agrave; chăm s&oacute;c sau dịch vụ.
					</li>
					<li>Thực hiện c&aacute;c cuộc khảo s&aacute;t sự h&agrave;i l&ograve;ng của kh&aacute;ch h&agrave;ng, thu thập
						phản hồi v&agrave; đề xuất cải thiện chất lượng dịch vụ.
					</li>
				</ul>
				<p className='font-bold'>4. Quản l&yacute; dữ liệu kh&aacute;ch h&agrave;ng:</p>
				<ul>
					<li>Cập nhật v&agrave; quản l&yacute; th&ocirc;ng tin kh&aacute;ch h&agrave;ng tr&ecirc;n hệ thống, đảm bảo
						t&iacute;nh ch&iacute;nh x&aacute;c v&agrave; bảo mật th&ocirc;ng tin.
					</li>
					<li>Theo d&otilde;i lịch sử giao dịch của kh&aacute;ch h&agrave;ng để kịp thời ph&aacute;t hiện v&agrave; xử
						l&yacute; c&aacute;c vấn đề tiềm ẩn.
					</li>
				</ul>
				<p className='font-bold'>5. B&aacute;o c&aacute;o c&ocirc;ng việc:</p>
				<ul>
					<li>Tổng hợp v&agrave; b&aacute;o c&aacute;o t&igrave;nh h&igrave;nh chăm s&oacute;c kh&aacute;ch h&agrave;ng
						h&agrave;ng tuần/th&aacute;ng/qu&yacute; cho cấp quản l&yacute;.
					</li>
					<li>Đề xuất c&aacute;c giải ph&aacute;p nhằm n&acirc;ng cao trải nghiệm kh&aacute;ch h&agrave;ng
						v&agrave; giảm thiểu khiếu nại.
					</li>
				</ul>
				<br/><h2 className='font-bold'>IV.QUYỀN HẠN:&nbsp;</h2>
				<ul>
					<li>Thực hiện được c&aacute;c c&ocirc;ng t&aacute;c chuy&ecirc;n m&ocirc;n nghiệp vụ theo đ&uacute;ng chức
						tr&aacute;ch
					</li>
					<li>Tham gia đ&oacute;ng g&oacute;p, x&acirc;y dựng kế hoạch ngắn hạn, trung hạn v&agrave; đề xuất c&aacute;c
						phương &aacute;n để quản l&yacute;, thực hiện c&ocirc;ng t&aacute;c thuộc phạm vi phụ tr&aacute;ch
						v&agrave; đ&aacute;nh gi&aacute; hiệu quả thực hiện
					</li>
					<li>Tổ chức, tham gia tổng hợp, ph&acirc;n t&iacute;ch đ&aacute;nh gi&aacute; c&ocirc;ng t&aacute;c
						chuy&ecirc;n m&ocirc;n, đề xuất chủ trương v&agrave; c&aacute;c biện ph&aacute;p n&acirc;ng cao hiệu quả.
					</li>
				</ul>
				<br/><h2 className='font-bold'>V.B&Aacute;O C&Aacute;O V&Agrave; UỶ QUYỀN</h2>
				<ol>
					<li>B&aacute;o c&aacute;o:&nbsp;</li>
				</ol>
				<ul>
					<li>Thực hiện b&aacute;o c&aacute;o định kỳ hoặc đột xuất theo y&ecirc;u cầu của Ban TGĐ về mọi th&ocirc;ng
						tin c&oacute; li&ecirc;n quan đến phạm vi tr&aacute;ch nhiệm được giao.
					</li>
				</ul>
				<ol start="2">
					<li>Ủy quyền:&nbsp;</li>
				</ol>
				<ul>
					<li>Kh&ocirc;ng</li>
				</ul>
				<br/><h2 className='font-bold'>VI.ĐIỀU KIỆN L&Agrave;M VIỆC&nbsp;</h2>
				<ul>
					<li>Thời gian l&agrave;m việc: Giờ h&agrave;nh ch&iacute;nh: Từ 8h00 đến 17h00</li>
				</ul>
				<ul>
					<li>Thời gian nghỉ h&agrave;ng tuần: Nghỉ chiều thứ 7 v&agrave; Ng&agrave;y Chủ nhật</li>
				</ul>
				<ul>
					<li>Trang thiết bị l&agrave;m việc được cung cấp (V&iacute; dụ: Laptop, PC, sim điện thoại,&hellip;): Laptop
					</li>
				</ul>
				<br/><h2 className='font-bold'>VII.TI&Ecirc;U CHUẨN TUYỂN DỤNG</h2>
				<p className='font-bold'>1. TR&Igrave;NH ĐỘ, KIẾN THỨC:</p>
				<ul>
					<li>Tr&igrave;nh độ học vấn tối thiểu: Đại học</li>
					<li>Tr&igrave;nh độ chuy&ecirc;n m&ocirc;n: Tốt nghiệp Cao đẳng trở l&ecirc;n, ưu ti&ecirc;n c&aacute;c
						ng&agrave;nh Dịch vụ kh&aacute;ch h&agrave;ng, T&agrave;i ch&iacute;nh, Ng&acirc;n h&agrave;ng, hoặc
						C&ocirc;ng nghệ th&ocirc;ng tin.
					</li>
					<li>Kiến thức li&ecirc;n quan: Hiểu biết về lĩnh vực c&ocirc;ng nghệ t&agrave;i ch&iacute;nh, đặc biệt
						l&agrave; c&aacute;c sản phẩm v&iacute; điện tử v&agrave; dịch vụ t&agrave;i ch&iacute;nh số l&agrave; một
						lợi thế.
					</li>
				</ul>
				<p className='font-bold'>2. KỸ NĂNG, KHẢ NĂNG:</p>
				<ul>
					<li>Ngoại ngữ: Giao tiếp lưu lo&aacute;t tiếng Anh</li>
					<li>Tin học: Th&agrave;nh thạo tin học Văn Ph&ograve;ng</li>
					<li>Kỹ năng li&ecirc;n quan:&nbsp;</li>
					<ul>
						<li>Kỹ năng giao tiếp tốt, xử l&yacute; t&igrave;nh huống linh hoạt.</li>
						<li>Sử dụng th&agrave;nh thạo c&aacute;c c&ocirc;ng cụ hỗ trợ chăm s&oacute;c kh&aacute;ch h&agrave;ng như
							CRM, tổng đ&agrave;i điện thoại, email.
						</li>
						<li>Kỹ năng l&agrave;m việc nh&oacute;m, phối hợp hiệu quả với c&aacute;c ph&ograve;ng ban kh&aacute;c.</li>
						<li>Chịu được &aacute;p lực c&ocirc;ng việc, xử l&yacute; đa nhiệm tốt.</li>
					</ul>
				</ul>
				<ul>
					<li>Khả năng l&agrave;m việc c&aacute; nh&acirc;n:</li>
					<ul>
						<li>Trung thực, si&ecirc;ng năng, nhiệt t&igrave;nh, cẩn thận.</li>
						<li>C&oacute; tinh thần tr&aacute;ch nhiệm.</li>
						<li>C&oacute; khả năng ph&acirc;n t&iacute;ch v&agrave; xử l&yacute; vấn đề.</li>
						<li>Sẵn s&agrave;ng đi c&ocirc;ng t&aacute;c</li>
					</ul>
				</ul>
				<p className='font-bold'>3. Y&Ecirc;U CẦU KH&Aacute;C:</p>
				<ul>
					<li>Kinh nghiệm c&ocirc;ng t&aacute;c:</li>
					<ul>
						<li>C&oacute; &iacute;t nhất 1-2 năm kinh nghiệm trong lĩnh vực chăm s&oacute;c kh&aacute;ch h&agrave;ng, ưu
							ti&ecirc;n ứng vi&ecirc;n đ&atilde; từng l&agrave;m trong ng&agrave;nh v&iacute; điện tử, fintech hoặc
							ng&acirc;n h&agrave;ng
						</li>
					</ul>
				</ul>
				<ul>
					<li>Giới t&iacute;nh: Kh&ocirc;ng ph&acirc;n biệt</li>
					<li>Độ tuổi: Kh&ocirc;ng giới hạn</li>
				</ul>
				<br/><h1 className='font-bold text-[#F05A24]'>C&Aacute;CH THỨC ỨNG TUYỂN:</h1>
				<p>Ứng vi&ecirc;n nộp hồ sơ trực tuyến bằng c&aacute;ch gửi th&ocirc;ng tin CV đến địa chỉ email <a className='underline text-primary'
					href="mailto:tuyendung@goopay.vn">tuyendung@goopay.vn</a> .<br/><br/><br/></p>
			</div>
		)
	},
	'chuyen-vien-hanh-chinh-nhan-su': {
		title: 'CHUYÊN VIÊN HÀNH CHÍNH NHÂN SỰ (HR & Admin Specialist)',
		content: (
			<div className='px-8 md:px-[256px] py-8'>
				<br/><h1 className='font-bold text-xl text-[#F05A24]'>M&Ocirc; TẢ C&Ocirc;NG VIỆC</h1>
				<br/><h2 className='font-bold'>I.TH&Ocirc;NG TIN CHUNG:</h2>
				<ul>
					<li>Địa điểm l&agrave;m việc: Th&agrave;nh phố Hồ Ch&iacute; Minh</li>
					<li>Ph&ograve;ng: H&agrave;nh ch&iacute;nh - Nh&acirc;n sự</li>
				</ul>
				<br/><h2 className='font-bold'>II.MỤC Đ&Iacute;CH:&nbsp;</h2>
				<ul>
					<li>Đảm bảo sự phối hợp hoạt động nhịp nh&agrave;ng giữa c&aacute;c nh&oacute;m, c&aacute;c bộ phận, gắn kết
						c&aacute;c ph&ograve;ng ban trong c&ocirc;ng ty, g&oacute;p phần v&agrave;o sự ph&aacute;t triển chung cuả
						C&ocirc;ng ty.
					</li>
				</ul>
				<br/><h2 className='font-bold'>III.TR&Aacute;CH NHIỆM:&nbsp;</h2>
				<p className='font-bold'>1. C&ocirc;ng t&aacute;c tuyển dụng:</p>
				<ul>
					<li>Lập kế hoạch tuyển dụng dựa tr&ecirc;n nhu cầu nh&acirc;n sự của c&aacute;c ph&ograve;ng ban trong
						c&ocirc;ng ty.
					</li>
					<li>Thực hiện quy tr&igrave;nh tuyển dụng (chủ động v&agrave; phối hợp vối BP Tuyển dụng Tập đo&agrave;n,
						c&aacute;c Trưởng ph&ograve;ng Goopay): đăng tin tuyển dụng, s&agrave;ng lọc hồ sơ, tổ chức phỏng vấn,
						đ&aacute;nh gi&aacute; ứng vi&ecirc;n v&agrave; đưa ra đề xuất tuyển dụng.
					</li>
					<li>X&acirc;y dựng v&agrave; duy tr&igrave; nguồn ứng vi&ecirc;n tiềm năng th&ocirc;ng qua c&aacute;c
						k&ecirc;nh tuyển dụng, sự kiện nghề nghiệp, v&agrave; mạng lưới quan hệ.
					</li>
					<li>Phối hợp với c&aacute;c ph&ograve;ng ban li&ecirc;n quan để đảm bảo quy tr&igrave;nh tuyển dụng diễn ra
						nhanh ch&oacute;ng v&agrave; hiệu quả.
					</li>
				</ul>
				<p className='font-bold'>2. Quản l&yacute; hồ sơ nh&acirc;n sự:</p>
				<ul>
					<li>Phối hợp với Bộ phận Tuyển dụng Tập đo&agrave;n hướng dẫn v&agrave; cập nhật hồ sơ nh&acirc;n vi&ecirc;n,
						hợp đồng lao động, v&agrave; c&aacute;c t&agrave;i liệu li&ecirc;n quan đến nh&acirc;n sự.
					</li>
					<li>Phối hợp với BP Tuyển dụng Tập đo&agrave;n theo d&otilde;i v&agrave; thực hiện c&aacute;c thủ tục
						li&ecirc;n quan đến hợp đồng lao động: k&yacute; kết, gia hạn, hoặc chấm dứt hợp đồng.
					</li>
					<li>Quản l&yacute; dữ liệu nh&acirc;n sự tr&ecirc;n hệ thống quản l&yacute; nh&acirc;n sự của c&ocirc;ng ty.
					</li>
				</ul>
				<p className='font-bold'>3. C&ocirc;ng t&aacute;c lương thưởng v&agrave; ph&uacute;c lợi:</p>
				<ul>
					<li>Hỗ trợ Bộ phận Lương Tập đo&agrave;n trong việc t&iacute;nh lương, thưởng, v&agrave; c&aacute;c chế độ
						ph&uacute;c lợi cho nh&acirc;n vi&ecirc;n h&agrave;ng th&aacute;ng.
					</li>
					<li>Phối hợp với Bộ phận Lương Tập đo&agrave;n thực hiện c&aacute;c thủ tục li&ecirc;n quan đến thuế TNCN, bảo
						hiểm x&atilde; hội, bảo hiểm y tế, bảo hiểm thất nghiệp cho nh&acirc;n vi&ecirc;n.
					</li>
					<li>Theo d&otilde;i v&agrave; cập nhật t&igrave;nh h&igrave;nh nghỉ ph&eacute;p, chấm c&ocirc;ng của
						nh&acirc;n vi&ecirc;n để đảm bảo t&iacute;nh ch&iacute;nh x&aacute;c v&agrave; c&ocirc;ng bằng.
					</li>
					<li>Phối hợp với Bộ phận Tuyển dụng Tập đo&agrave;n giải quyết thủ tục chấm dứt hợp đồng lao động hoặc
						c&aacute;c loại hợp đồng kh&aacute;c cho nh&acirc;n vi&ecirc;n.
					</li>
				</ul>
				<p className='font-bold'>4. Đ&agrave;o tạo v&agrave; ph&aacute;t triển nh&acirc;n sự:</p>
				<ul>
					<li>Phối hợp với BP Đ&agrave;o tạo Tập đo&agrave;n x&acirc;y dựng v&agrave; triển khai c&aacute;c chương
						tr&igrave;nh đ&agrave;o tạo nội bộ nhằm n&acirc;ng cao kỹ năng v&agrave; kiến thức cho nh&acirc;n
						vi&ecirc;n.
					</li>
					<li>Hỗ trợ BP Đ&agrave;o tạo Tập đo&agrave;n trong việc tổ chức c&aacute;c buổi đ&agrave;o tạo, hội thảo hoặc
						sự kiện ph&aacute;t triển kỹ năng cho nh&acirc;n vi&ecirc;n.
					</li>
					<li>Theo d&otilde;i v&agrave; đ&aacute;nh gi&aacute; hiệu quả c&aacute;c chương tr&igrave;nh đ&agrave;o tạo,
						từ đ&oacute; đề xuất c&aacute;c biện ph&aacute;p cải thiện.
					</li>
					<li>Phố biến, hướng dẫn c&aacute;c quy định, ch&iacute;nh s&aacute;ch của Tập đo&agrave;n/ C&ocirc;ng ty cho
						nh&acirc;n vi&ecirc;n.
					</li>
				</ul>
				<p className='font-bold'>5. X&acirc;y dựng v&agrave; ph&aacute;t triển văn h&oacute;a c&ocirc;ng ty:</p>
				<ul>
					<li>Phối hợp với Ban NSHC Tập đo&agrave;n hoặc tự chủ động tổ chức c&aacute;c hoạt động nội bộ nhằm gắn kết
						nh&acirc;n vi&ecirc;n v&agrave; ph&aacute;t triển văn h&oacute;a doanh nghiệp.
					</li>
					<li>Phối hợp với Ban NSHC Tập đo&agrave;n giải quyết c&aacute;c khiếu nại, thắc mắc của nh&acirc;n vi&ecirc;n
						li&ecirc;n quan đến chế độ ph&uacute;c lợi, ch&iacute;nh s&aacute;ch c&ocirc;ng ty.
					</li>
					<li>Đề xuất c&aacute;c hoạt động chăm s&oacute;c sức khỏe, đời sống tinh thần cho nh&acirc;n vi&ecirc;n, tạo
						m&ocirc;i trường l&agrave;m việc th&acirc;n thiện v&agrave; chuy&ecirc;n nghiệp.
					</li>
					<li>6.Tu&acirc;n thủ ph&aacute;p luật lao động:</li>
					<li>Đảm bảo c&aacute;c hoạt động nh&acirc;n sự của c&ocirc;ng ty tu&acirc;n thủ đ&uacute;ng c&aacute;c quy
						định của ph&aacute;p luật về lao động v&agrave; nội quy của c&ocirc;ng ty.
					</li>
					<li>Cập nhật c&aacute;c thay đổi trong quy định ph&aacute;p luật li&ecirc;n quan đến nh&acirc;n sự
						v&agrave; đưa ra c&aacute;c khuyến nghị ph&ugrave; hợp để điều chỉnh ch&iacute;nh s&aacute;ch c&ocirc;ng ty.
					</li>
					<li>Tổng hợp v&agrave; chuẩn bị hồ sơ, tổ chức xử l&yacute; kỷ luật lao động.</li>
				</ul>
				<p className='font-bold'>7. C&ocirc;ng t&aacute;c h&agrave;nh ch&iacute;nh:</p>
				<ul>
					<li>Quản l&yacute; v&agrave; theo d&otilde;i c&aacute;c hoạt động h&agrave;nh ch&iacute;nh trong c&ocirc;ng ty
						như: đặt mua văn ph&ograve;ng phẩm, quản l&yacute; t&agrave;i sản c&ocirc;ng ty, điều phối c&ocirc;ng
						t&aacute;c hậu cần cho c&aacute;c sự kiện nội bộ.
					</li>
					<li>Hướng dẫn, nhắc nhở 5S nhằm đảm bảo văn ph&ograve;ng l&agrave;m việc lu&ocirc;n trong trạng th&aacute;i
						sạch sẽ, ngăn nắp v&agrave; đ&aacute;p ứng đủ c&aacute;c nhu cầu của nh&acirc;n vi&ecirc;n.
					</li>
					<li>Quản l&yacute; việc cấp ph&aacute;t, bảo tr&igrave; v&agrave; sử dụng hiệu quả c&aacute;c trang thiết bị
						văn ph&ograve;ng, đảm bảo m&ocirc;i trường l&agrave;m việc thuận tiện v&agrave; an to&agrave;n.
					</li>
					<li>Soạn thảo văn bản h&agrave;nh ch&iacute;nh;</li>
					<li>Lưu trữ c&aacute;c văn bản đi &ndash; đến (c&aacute;c văn bản h&agrave;nh ch&iacute;nh, ph&aacute;p
						l&yacute; chung, trừ văn bản thuộc chuy&ecirc;n m&ocirc;n của c&aacute;c ph&ograve;ng ban).
					</li>
				</ul>
				<p className='font-bold'>8. Hỗ trợ c&aacute;c hoạt động kh&aacute;c của ph&ograve;ng H&agrave;nh ch&iacute;nh - Nh&acirc;n sự:</p>
				<ul>
					<li>Thực hiện c&aacute;c c&ocirc;ng việc kh&aacute;c theo y&ecirc;u cầu của Trưởng ph&ograve;ng Đối t&aacute;c
						Nh&acirc;n sự.
					</li>
					<li>Phối hợp với c&aacute;c ph&ograve;ng ban để hỗ trợ tổ chức c&aacute;c sự kiện, chương tr&igrave;nh của
						c&ocirc;ng ty.
					</li>
				</ul>
				<br/><h2 className='font-bold'>IV.QUYỀN HẠN:&nbsp;</h2>
				<ul>
					<li>Thực hiện được c&aacute;c c&ocirc;ng t&aacute;c chuy&ecirc;n m&ocirc;n nghiệp vụ theo đ&uacute;ng chức
						tr&aacute;ch
					</li>
					<li>Tham gia đ&oacute;ng g&oacute;p, x&acirc;y dựng chiến lược, kế hoạch ngắn hạn, trung hạn v&agrave; đề xuất
						c&aacute;c phương &aacute;n để quản l&yacute;, thực hiện c&ocirc;ng t&aacute;c thuộc phạm vi phụ
						tr&aacute;ch v&agrave; đ&aacute;nh gi&aacute; hiệu quả thực hiện
					</li>
					<li>Tổ chức, tham gia tổng hợp, ph&acirc;n t&iacute;ch đ&aacute;nh gi&aacute; c&ocirc;ng t&aacute;c
						chuy&ecirc;n m&ocirc;n, đề xuất chủ trương v&agrave; c&aacute;c biện ph&aacute;p n&acirc;ng cao hiệu quả.
					</li>
				</ul>
				<br/><h2 className='font-bold'>V.B&Aacute;O C&Aacute;O V&Agrave; UỶ QUYỀN</h2>
				<ol>
					<li>B&aacute;o c&aacute;o:&nbsp;</li>
				</ol>
				<ul>
					<li>Thực hiện b&aacute;o c&aacute;o định kỳ hoặc đột xuất theo y&ecirc;u cầu của Ban TGĐ về mọi th&ocirc;ng
						tin c&oacute; li&ecirc;n quan đến phạm vi tr&aacute;ch nhiệm được giao.
					</li>
				</ul>
				<ol start="2">
					<li>Ủy quyền:&nbsp;</li>
				</ol>
				<ul>
					<li>Kh&ocirc;ng</li>
				</ul>
				<br/><h2 className='font-bold'>VI.ĐIỀU KIỆN L&Agrave;M VIỆC&nbsp;</h2>
				<ul>
					<li>Thời gian l&agrave;m việc: Giờ h&agrave;nh ch&iacute;nh: Từ 8h00 đến 17h00</li>
				</ul>
				<ul>
					<li>Thời gian nghỉ h&agrave;ng tuần: Nghỉ chiều thứ 7 v&agrave; Ng&agrave;y Chủ nhật</li>
				</ul>
				<ul>
					<li>Trang thiết bị l&agrave;m việc được cung cấp (V&iacute; dụ: Laptop, PC, sim điện thoại,&hellip;): Laptop
					</li>
				</ul>
				<br/><h2 className='font-bold'>VII.TI&Ecirc;U CHUẨN TUYỂN DỤNG</h2>
				<p className='font-bold'>1. TR&Igrave;NH ĐỘ, KIẾN THỨC:</p>
				<ul>
					<li>Tr&igrave;nh độ học vấn tối thiểu: Cao đẳng</li>
					<li>Tr&igrave;nh độ chuy&ecirc;n m&ocirc;n: Tốt nghiệp Đại học/Cao đẳng chuy&ecirc;n ng&agrave;nh Quản trị
						Nh&acirc;n sự, Kinh tế, Luật, hoặc c&aacute;c ng&agrave;nh li&ecirc;n quan.
					</li>
					<li>Kiến thức li&ecirc;n quan: Hiểu biết về c&aacute;c quy định của ph&aacute;p luật lao động, bảo hiểm
						x&atilde; hội v&agrave; thuế thu nhập c&aacute; nh&acirc;n
					</li>
				</ul>
				<p className='font-bold'>2. KỸ NĂNG, KHẢ NĂNG:</p>
				<ul>
					<li>Ngoại ngữ: C&oacute; thể đọc hiểu t&agrave;i liệu v&agrave; giao tiếp bằng tiếng Anh</li>
					<li>Tin học: Th&agrave;nh thạo tin học Văn Ph&ograve;ng</li>
					<li>Kỹ năng li&ecirc;n quan:&nbsp;</li>
					<ul>
						<li>Kỹ năng tổ chức c&ocirc;ng việc v&agrave; quản l&yacute; thời gian tốt.</li>
						<li>Khả năng giao tiếp tốt, biết lắng nghe v&agrave; xử l&yacute; c&aacute;c vấn đề ph&aacute;t sinh trong
							m&ocirc;i trường l&agrave;m việc.
						</li>
						<li>Kỹ năng sử dụng c&aacute;c phần mềm quản l&yacute; nh&acirc;n sự, văn ph&ograve;ng (Word, Excel,
							PowerPoint) th&agrave;nh thạo.
						</li>
					</ul>
				</ul>
				<ul>
					<li>Khả năng l&agrave;m việc c&aacute; nh&acirc;n:</li>
					<ul>
						<li>Nhiệt t&igrave;nh, tỉ mỉ, c&oacute; tr&aacute;ch nhiệm v&agrave; chủ động trong c&ocirc;ng việc.</li>
						<li>Vui vẻ, hoạt b&aacute;t, h&ograve;a đồng.</li>
						<li>C&oacute; thể l&agrave;m việc độc lập hoặc l&agrave;m việc nh&oacute;m.</li>
						<li>Ch&iacute;nh trực.</li>
						<li>Sẵn s&agrave;ng đi c&ocirc;ng t&aacute;c</li>
					</ul>
				</ul>
				<p className='font-bold'>3. Y&Ecirc;U CẦU KH&Aacute;C:</p>
				<ul>
					<li>Kinh nghiệm c&ocirc;ng t&aacute;c:</li>
					<ul>
						<li>C&oacute; &iacute;t nhất 1-2 năm kinh nghiệm trong lĩnh vực h&agrave;nh ch&iacute;nh, nh&acirc;n sự, đặc
							biệt l&agrave; trong c&aacute;c c&ocirc;ng ty c&ocirc;ng nghệ hoặc fintech l&agrave; một lợi thế.
						</li>
					</ul>
				</ul>
				<ul>
					<li>Giới t&iacute;nh: Kh&ocirc;ng ph&acirc;n biệt</li>
					<li>Độ tuổi: Kh&ocirc;ng giới hạn</li>
				</ul>
				<br/><h1 className='font-bold text-[#F05A24]'>C&Aacute;CH THỨC ỨNG TUYỂN:</h1>
				<p>Ứng vi&ecirc;n nộp hồ sơ trực tuyến bằng c&aacute;ch gửi th&ocirc;ng tin CV đến địa chỉ email <a className='underline text-primary'
					href="mailto:tuyendung@goopay.vn">tuyendung@goopay.vn</a> .<br/><br/></p>
			</div>
		)
	},
	'chuyen-vien-ke-toan-tong-hop': {
		title: 'CHUYÊN VIÊN KẾ TOÁN TỔNG HỢP (General Accounting Specialist)',
		content: (
			<div className='px-8 md:px-[256px] py-8'>
				<br/><h1 className='font-bold text-xl text-[#F05A24]'>M&Ocirc; TẢ C&Ocirc;NG VIỆC</h1>
				<br/><h2 className='font-bold'>I.TH&Ocirc;NG TIN CHUNG:</h2>
				<ul>
					<li>Địa điểm l&agrave;m việc: Th&agrave;nh phố Hồ Ch&iacute; Minh</li>
					<li>Ph&ograve;ng: T&agrave;i ch&iacute;nh - Kế to&aacute;n</li>
				</ul>
				<br/><h2 className='font-bold'>II.MỤC Đ&Iacute;CH:&nbsp;</h2>
				<ul>
					<li>Chịu tr&aacute;ch nhiệm thu thập, tổng hợp, ghi ch&eacute;p v&agrave; thống k&ecirc; tổng qu&aacute;t
						những số liệu, dữ liệu, th&ocirc;ng tin ở tr&ecirc;n c&aacute;c t&agrave;i khoản, sổ s&aacute;ch kế
						to&aacute;n, b&aacute;o c&aacute;o t&agrave;i ch&iacute;nh theo từng kỳ kế to&aacute;n của C&ocirc;ng ty.
					</li>
				</ul>
				<br/><h2 className='font-bold'>III.TR&Aacute;CH NHIỆM:&nbsp;</h2>
				<p className='font-bold'>1. Thực hiện nghiệp vụ kế to&aacute;n tổng hợp:</p>
				<ul>
					<li>Thực hiện hạch to&aacute;n c&aacute;c nghiệp vụ kế to&aacute;n ph&aacute;t sinh h&agrave;ng ng&agrave;y,
						bao gồm: thu, chi, c&ocirc;ng nợ, t&agrave;i sản cố định, lương, thuế v&agrave; c&aacute;c khoản
						tr&iacute;ch theo lương.
					</li>
					<li>Tập hợp, ph&acirc;n bổ chi ph&iacute; sản xuất, t&iacute;nh gi&aacute; th&agrave;nh v&agrave; kiểm tra
						t&iacute;nh ch&iacute;nh x&aacute;c của c&aacute;c số liệu li&ecirc;n quan.
					</li>
					<li>Theo d&otilde;i v&agrave; kiểm so&aacute;t c&aacute;c khoản thu, chi, thanh to&aacute;n c&ocirc;ng nợ, đối
						chiếu với c&aacute;c đối t&aacute;c, kh&aacute;ch h&agrave;ng v&agrave; nh&agrave; cung cấp.
					</li>
					<li>Cập nhật v&agrave; đối chiếu thường xuy&ecirc;n sổ kế to&aacute;n chi tiết v&agrave; sổ kế to&aacute;n
						tổng hợp, đảm bảo khớp đ&uacute;ng giữa c&aacute;c sổ v&agrave; t&agrave;i liệu kế to&aacute;n.
					</li>
				</ul>
				<p className='font-bold'>2. Quản l&yacute; t&agrave;i khoản ng&acirc;n h&agrave;ng v&agrave; tiền mặt:</p>
				<ul>
					<li>Theo d&otilde;i, đối chiếu số dư t&agrave;i khoản ng&acirc;n h&agrave;ng h&agrave;ng ng&agrave;y, đảm bảo
						ch&iacute;nh x&aacute;c v&agrave; đầy đủ c&aacute;c giao dịch thanh to&aacute;n qua v&iacute; điện tử
						v&agrave; ng&acirc;n h&agrave;ng.
					</li>
					<li>Kiểm tra, quản l&yacute; d&ograve;ng tiền, đảm bảo c&aacute;c khoản thu chi hợp l&yacute;, kịp thời
						v&agrave; đ&uacute;ng quy định.
					</li>
					<li>Lập kế hoạch d&ograve;ng tiền ngắn hạn v&agrave; d&agrave;i hạn để đảm bảo c&acirc;n đối t&agrave;i
						ch&iacute;nh cho c&aacute;c hoạt động của c&ocirc;ng ty.
					</li>
				</ul>
				<p className='font-bold'>3. Quản l&yacute; v&agrave; thực hiện c&aacute;c b&aacute;o c&aacute;o t&agrave;i ch&iacute;nh:</p>
				<ul>
					<li>Lập c&aacute;c b&aacute;o c&aacute;o t&agrave;i ch&iacute;nh h&agrave;ng th&aacute;ng, qu&yacute;, năm
						theo y&ecirc;u cầu của Ban Gi&aacute;m đốc v&agrave; Kế to&aacute;n trưởng.
					</li>
					<li>Thực hiện ph&acirc;n t&iacute;ch c&aacute;c số liệu t&agrave;i ch&iacute;nh, đề xuất c&aacute;c biện
						ph&aacute;p tối ưu chi ph&iacute;, cải thiện lợi nhuận v&agrave; tăng cường hiệu quả t&agrave;i
						ch&iacute;nh.
					</li>
					<li>Kiểm tra v&agrave; đảm bảo t&iacute;nh ch&iacute;nh x&aacute;c của c&aacute;c b&aacute;o c&aacute;o
						t&agrave;i ch&iacute;nh, giải tr&igrave;nh số liệu khi c&oacute; y&ecirc;u cầu kiểm to&aacute;n hoặc
						c&aacute;c cơ quan chức năng.
					</li>
				</ul>
				<p className='font-bold'>4. Quản l&yacute; c&ocirc;ng t&aacute;c thuế:</p>
				<ul>
					<li>Thực hiện c&aacute;c thủ tục khai b&aacute;o thuế định kỳ (thuế GTGT, thuế TNDN, thuế TNCN,
						v&agrave; c&aacute;c loại thuế kh&aacute;c) theo đ&uacute;ng quy định của ph&aacute;p luật.
					</li>
					<li>Chuẩn bị v&agrave; nộp tờ khai thuế h&agrave;ng th&aacute;ng, h&agrave;ng qu&yacute; v&agrave; quyết
						to&aacute;n thuế h&agrave;ng năm.
					</li>
					<li>Đối chiếu, kiểm tra số liệu kế to&aacute;n v&agrave; thuế, đảm bảo kh&ocirc;ng c&oacute; sự ch&ecirc;nh
						lệch giữa sổ kế to&aacute;n v&agrave; sổ thuế.
					</li>
				</ul>
				<p className='font-bold'>5. Quản l&yacute; c&ocirc;ng nợ v&agrave; t&agrave;i sản cố định:</p>
				<ul>
					<li>Theo d&otilde;i v&agrave; quản l&yacute; c&ocirc;ng nợ kh&aacute;ch h&agrave;ng, nh&agrave; cung cấp, đảm
						bảo c&aacute;c khoản phải thu, phải trả được quản l&yacute; chặt chẽ, đ&uacute;ng hạn.
					</li>
					<li>Quản l&yacute; v&agrave; hạch to&aacute;n t&agrave;i sản cố định, c&ocirc;ng cụ dụng cụ, t&iacute;nh
						to&aacute;n v&agrave; ph&acirc;n bổ khấu hao theo quy định của ph&aacute;p luật v&agrave; ch&iacute;nh
						s&aacute;ch của c&ocirc;ng ty.
					</li>
				</ul>
				<p className='font-bold'>6. Kiểm so&aacute;t nội bộ:</p>
				<ul>
					<li>Thực hiện c&aacute;c c&ocirc;ng t&aacute;c kiểm tra, kiểm so&aacute;t nội bộ về c&aacute;c nghiệp vụ kế
						to&aacute;n, t&agrave;i ch&iacute;nh nhằm đảm bảo t&iacute;nh ch&iacute;nh x&aacute;c, hợp
						l&yacute; v&agrave; tu&acirc;n thủ c&aacute;c quy định của ph&aacute;p luật.
					</li>
					<li>Đưa ra c&aacute;c đề xuất cải thiện quy tr&igrave;nh kiểm so&aacute;t nội bộ để n&acirc;ng cao t&iacute;nh
						minh bạch v&agrave; hiệu quả hoạt động của hệ thống t&agrave;i ch&iacute;nh kế to&aacute;n.
					</li>
				</ul>
				<p className='font-bold'>7. Phối hợp với c&aacute;c bộ phận kh&aacute;c:</p>
				<ul>
					<li>Phối hợp với c&aacute;c ph&ograve;ng ban kh&aacute;c trong việc đối chiếu số liệu, đảm bảo t&iacute;nh
						ch&iacute;nh x&aacute;c v&agrave; thống nhất giữa c&aacute;c bộ phận.
					</li>
					<li>Hỗ trợ Kế to&aacute;n trưởng trong việc chuẩn bị hồ sơ, t&agrave;i liệu phục vụ c&aacute;c đợt kiểm
						to&aacute;n nội bộ, kiểm to&aacute;n thuế hoặc khi c&oacute; y&ecirc;u cầu từ c&aacute;c cơ quan quản
						l&yacute;.
					</li>
				</ul>
				<p className='font-bold'>8. Hỗ trợ lập ng&acirc;n s&aacute;ch v&agrave; kế hoạch t&agrave;i ch&iacute;nh:</p>
				<ul>
					<li>Tham gia v&agrave;o qu&aacute; tr&igrave;nh lập ng&acirc;n s&aacute;ch h&agrave;ng năm cho c&ocirc;ng ty,
						theo d&otilde;i v&agrave; kiểm so&aacute;t việc thực hiện ng&acirc;n s&aacute;ch của c&aacute;c ph&ograve;ng
						ban.
					</li>
					<li>Đưa ra c&aacute;c đề xuất, khuyến nghị nhằm cải thiện việc sử dụng ng&acirc;n s&aacute;ch v&agrave; quản
						l&yacute; chi ph&iacute; hiệu quả.
					</li>
				</ul>
				<br/><h2 className='font-bold'>IV.QUYỀN HẠN:&nbsp;</h2>
				<ul>
					<li>Thực hiện được c&aacute;c c&ocirc;ng t&aacute;c chuy&ecirc;n m&ocirc;n nghiệp vụ theo đ&uacute;ng chức
						tr&aacute;ch
					</li>
					<li>Tham gia đ&oacute;ng g&oacute;p, x&acirc;y dựng kế hoạch ngắn hạn, trung hạn v&agrave; đề xuất c&aacute;c
						phương &aacute;n để quản l&yacute;, thực hiện c&ocirc;ng t&aacute;c thuộc phạm vi phụ tr&aacute;ch
						v&agrave; đ&aacute;nh gi&aacute; hiệu quả thực hiện
					</li>
					<li>Tổ chức, tham gia tổng hợp, ph&acirc;n t&iacute;ch đ&aacute;nh gi&aacute; c&ocirc;ng t&aacute;c
						chuy&ecirc;n m&ocirc;n, đề xuất chủ trương v&agrave; c&aacute;c biện ph&aacute;p n&acirc;ng cao hiệu quả.
					</li>
				</ul>
				<br/><h2 className='font-bold'>V.B&Aacute;O C&Aacute;O V&Agrave; UỶ QUYỀN</h2>
				<ol>
					<li>B&aacute;o c&aacute;o:&nbsp;</li>
				</ol>
				<ul>
					<li>Thực hiện b&aacute;o c&aacute;o định kỳ hoặc đột xuất theo y&ecirc;u cầu của Ban TGĐ về mọi th&ocirc;ng
						tin c&oacute; li&ecirc;n quan đến phạm vi tr&aacute;ch nhiệm được giao.
					</li>
				</ul>
				<ol start="2">
					<li>Ủy quyền:&nbsp;</li>
				</ol>
				<ul>
					<li>Kh&ocirc;ng</li>
				</ul>
				<br/><h2 className='font-bold'>VI.ĐIỀU KIỆN L&Agrave;M VIỆC&nbsp;</h2>
				<ul>
					<li>Thời gian l&agrave;m việc: Giờ h&agrave;nh ch&iacute;nh: Từ 8h00 đến 17h00</li>
				</ul>
				<ul>
					<li>Thời gian nghỉ h&agrave;ng tuần: Nghỉ chiều thứ 7 v&agrave; Ng&agrave;y Chủ nhật</li>
				</ul>
				<ul>
					<li>Trang thiết bị l&agrave;m việc được cung cấp (V&iacute; dụ: Laptop, PC, sim điện thoại,&hellip;): Laptop
					</li>
				</ul>
				<br/><h2 className='font-bold'>VII.TI&Ecirc;U CHUẨN TUYỂN DỤNG</h2>
				<p className='font-bold'>1. TR&Igrave;NH ĐỘ, KIẾN THỨC:</p>
				<ul>
					<li>Tr&igrave;nh độ học vấn tối thiểu: Đại học</li>
					<li>Tr&igrave;nh độ chuy&ecirc;n m&ocirc;n: Tốt nghiệp Đại học trở l&ecirc;n chuy&ecirc;n ng&agrave;nh Kế
						to&aacute;n, T&agrave;i ch&iacute;nh hoặc c&aacute;c ng&agrave;nh li&ecirc;n quan.
					</li>
					<li>Kiến thức li&ecirc;n quan: Nắm vững c&aacute;c quy định của ph&aacute;p luật về thuế, t&agrave;i
						ch&iacute;nh, kế to&aacute;n v&agrave; c&aacute;c quy định li&ecirc;n quan.
					</li>
				</ul>
				<p className='font-bold'>2. KỸ NĂNG, KHẢ NĂNG:</p>
				<ul>
					<li>Ngoại ngữ: C&oacute; thể đọc hiểu tiếng Anh chuy&ecirc;n ng&agrave;nh</li>
					<li>Tin học: Th&agrave;nh thạo tin học Văn Ph&ograve;ng</li>
					<li>Kỹ năng li&ecirc;n quan:&nbsp;</li>
					<ul>
						<li>Th&agrave;nh thạo trong việc sử dụng c&aacute;c phần mềm kế to&aacute;n v&agrave; c&aacute;c c&ocirc;ng
							cụ quản l&yacute; t&agrave;i ch&iacute;nh (Misa, Fast, Excel, v.v.).
						</li>
						<li>Kỹ năng ph&acirc;n t&iacute;ch, tổng hợp số liệu tốt, ch&uacute; &yacute; đến chi tiết v&agrave; khả
							năng lập b&aacute;o c&aacute;o t&agrave;i ch&iacute;nh ch&iacute;nh x&aacute;c.
						</li>
						<li>Kỹ năng giao tiếp tốt v&agrave; c&oacute; khả năng l&agrave;m việc độc lập cũng như l&agrave;m việc
							nh&oacute;m hiệu quả.
						</li>
					</ul>
				</ul>
				<ul>
					<li>Khả năng l&agrave;m việc c&aacute; nh&acirc;n:</li>
					<ul>
						<li>Ch&iacute;nh trực.</li>
						<li>Chủ động.</li>
						<li>Chăm chỉ; C&oacute; tinh thần tr&aacute;ch nhiệm.</li>
						<li>Sẵn s&agrave;ng đi c&ocirc;ng t&aacute;c.</li>
					</ul>
				</ul>
				<p className='font-bold'>3. Y&Ecirc;U CẦU KH&Aacute;C:</p>
				<ul>
					<li>Kinh nghiệm c&ocirc;ng t&aacute;c:</li>
					<ul>
						<li>C&oacute; &iacute;t nhất 2-3 năm kinh nghiệm trong lĩnh vực kế to&aacute;n tổng hợp, đặc biệt trong
							c&aacute;c c&ocirc;ng ty c&ocirc;ng nghệ t&agrave;i ch&iacute;nh, v&iacute; điện tử hoặc c&aacute;c tổ
							chức t&agrave;i ch&iacute;nh l&agrave; một lợi thế.
						</li>
					</ul>
				</ul>
				<ul>
					<li>Giới t&iacute;nh: Kh&ocirc;ng ph&acirc;n biệt</li>
					<li>Độ tuổi: Kh&ocirc;ng giới hạn</li>
				</ul>
				<br/><h1 className='font-bold text-[#F05A24]'>C&Aacute;CH THỨC ỨNG TUYỂN:</h1>
				<p>Ứng vi&ecirc;n nộp hồ sơ trực tuyến bằng c&aacute;ch gửi th&ocirc;ng tin CV đến địa chỉ email <a className='underline text-primary'
					href="mailto:tuyendung@goopay.vn">tuyendung@goopay.vn</a> .</p>
				<p><br/><br/><br/></p>
			</div>
		)
	},
	'chuyen-vien-phat-trien-kinh-doanh': {
		title: 'CHUYÊN VIÊN PHÁT TRIỂN KINH DOANH (Sale Development Specialist)',
		content: (
			<div className='px-8 md:px-[256px] py-8'>
				<br/><h1 className='font-bold text-xl text-[#F05A24]'>M&Ocirc; TẢ C&Ocirc;NG VIỆC</h1>
				<br/><h2 className='font-bold'>I.TH&Ocirc;NG TIN CHUNG:</h2>
				<ul>
					<li>Địa điểm l&agrave;m việc: Th&agrave;nh phố Hồ Ch&iacute; Minh</li>
					<li>Ph&ograve;ng: Ph&aacute;t Triển Kinh Doanh</li>
				</ul>
				<br/><h2 className='font-bold'>II.MỤC Đ&Iacute;CH:&nbsp;</h2>
				<ul>
					<li>Đ&oacute;ng vai tr&ograve; quan trọng trong việc mở rộng thị trường, x&acirc;y dựng mối quan hệ đối
						t&aacute;c chiến lược v&agrave; tạo ra c&aacute;c cơ hội kinh doanh mới cho c&ocirc;ng ty.
					</li>
				</ul>
				<br/><h2 className='font-bold'>III.TR&Aacute;CH NHIỆM:&nbsp;</h2>
				<p className='font-bold'>1. Nghi&ecirc;n cứu v&agrave; ph&acirc;n t&iacute;ch thị trường:</p>
				<ul>
					<li>Thực hiện nghi&ecirc;n cứu, ph&acirc;n t&iacute;ch xu hướng thị trường v&agrave; đối thủ cạnh tranh trong
						ng&agrave;nh v&iacute; điện tử, c&ocirc;ng nghệ t&agrave;i ch&iacute;nh (fintech).
					</li>
					<li>Ph&acirc;n t&iacute;ch nhu cầu v&agrave; h&agrave;nh vi của người d&ugrave;ng, từ đ&oacute; đề xuất
						c&aacute;c chiến lược ph&aacute;t triển kinh doanh hiệu quả.
					</li>
					<li>Đề xuất c&aacute;c kế hoạch, phương ph&aacute;p tiếp cận kh&aacute;ch h&agrave;ng mục ti&ecirc;u
						v&agrave; mở rộng mạng lưới đối t&aacute;c chiến lược.
					</li>
				</ul>
				<p className='font-bold'>2. Ph&aacute;t triển mối quan hệ đối t&aacute;c v&agrave; kh&aacute;ch h&agrave;ng:</p>
				<ul>
					<li>X&acirc;y dựng v&agrave; duy tr&igrave; mối quan hệ hợp t&aacute;c với c&aacute;c đối t&aacute;c như
						ng&acirc;n h&agrave;ng, tổ chức t&agrave;i ch&iacute;nh, v&agrave; c&aacute;c c&ocirc;ng ty thương mại điện
						tử để mở rộng hệ sinh th&aacute;i của v&iacute; điện tử.
					</li>
					<li>T&igrave;m kiếm, tiếp cận v&agrave; đ&agrave;m ph&aacute;n với c&aacute;c đối t&aacute;c kinh doanh tiềm
						năng để triển khai c&aacute;c dịch vụ v&agrave; t&iacute;nh năng mới của v&iacute; điện tử.
					</li>
					<li>Ph&aacute;t triển chiến lược hợp t&aacute;c nhằm mang lại gi&aacute; trị gia tăng cho cả đối t&aacute;c
						v&agrave; kh&aacute;ch h&agrave;ng sử dụng dịch vụ.
					</li>
				</ul>
				<p className='font-bold'>3. X&acirc;y dựng chiến lược ph&aacute;t triển kinh doanh:</p>
				<ul>
					<li>Tham gia x&acirc;y dựng chiến lược ph&aacute;t triển kinh doanh d&agrave;i hạn cho c&ocirc;ng ty, đảm bảo
						sự ph&aacute;t triển bền vững v&agrave; tăng trưởng về doanh thu.
					</li>
					<li>Đề xuất v&agrave; thực hiện c&aacute;c chiến lược b&aacute;n h&agrave;ng v&agrave; tiếp thị nhằm tăng
						cường nhận diện thương hiệu, thu h&uacute;t kh&aacute;ch h&agrave;ng mới v&agrave; giữ ch&acirc;n
						kh&aacute;ch h&agrave;ng hiện tại.
					</li>
					<li>Phối hợp với c&aacute;c ph&ograve;ng ban kh&aacute;c (Marketing, Ph&aacute;t triển sản phẩm, Kỹ thuật) để
						đảm bảo c&aacute;c chiến lược kinh doanh được triển khai đồng bộ v&agrave; hiệu quả.
					</li>
				</ul>
				<p className='font-bold'>4. Quản l&yacute; v&agrave; ph&aacute;t triển c&aacute;c k&ecirc;nh b&aacute;n h&agrave;ng:</p>
				<ul>
					<li>Ph&aacute;t triển c&aacute;c k&ecirc;nh b&aacute;n h&agrave;ng trực tuyến v&agrave; ngoại tuyến cho sản
						phẩm v&iacute; điện tử.
					</li>
					<li>Lập kế hoạch v&agrave; thực hiện c&aacute;c chương tr&igrave;nh khuyến m&atilde;i, ưu đ&atilde;i để
						th&uacute;c đẩy việc sử dụng v&iacute; điện tử v&agrave; tăng trưởng người d&ugrave;ng.
					</li>
					<li>Theo d&otilde;i, đ&aacute;nh gi&aacute; hiệu quả của từng k&ecirc;nh b&aacute;n h&agrave;ng v&agrave; đưa
						ra c&aacute;c đề xuất cải tiến.
					</li>
				</ul>
				<p className='font-bold'>5. Tư vấn v&agrave; hỗ trợ kh&aacute;ch h&agrave;ng:</p>
				<ul>
					<li>Hỗ trợ kh&aacute;ch h&agrave;ng v&agrave; đối t&aacute;c trong việc triển khai c&aacute;c dịch vụ
						v&agrave; t&iacute;nh năng mới của v&iacute; điện tử.
					</li>
					<li>Giải quyết c&aacute;c vấn đề ph&aacute;t sinh, tư vấn cho kh&aacute;ch h&agrave;ng v&agrave; đối
						t&aacute;c về c&aacute;ch sử dụng sản phẩm v&agrave; dịch vụ một c&aacute;ch hiệu quả nhất.
					</li>
					<li>Thu thập phản hồi từ kh&aacute;ch h&agrave;ng, ph&acirc;n t&iacute;ch v&agrave; đề xuất c&aacute;c giải
						ph&aacute;p để n&acirc;ng cao trải nghiệm người d&ugrave;ng.
					</li>
				</ul>
				<p className='font-bold'>6. Theo d&otilde;i v&agrave; b&aacute;o c&aacute;o kết quả kinh doanh:</p>
				<ul>
					<li>Theo d&otilde;i, tổng hợp v&agrave; b&aacute;o c&aacute;o kết quả kinh doanh h&agrave;ng th&aacute;ng,
						qu&yacute;, năm, bao gồm c&aacute;c chỉ ti&ecirc;u doanh thu, lợi nhuận v&agrave; c&aacute;c KPI
						kh&aacute;c.
					</li>
					<li>Đ&aacute;nh gi&aacute; hiệu quả c&aacute;c chiến lược kinh doanh, ph&acirc;n t&iacute;ch kết quả
						v&agrave; đưa ra c&aacute;c biện ph&aacute;p cải thiện nếu cần.
					</li>
					<li>X&acirc;y dựng c&aacute;c b&aacute;o c&aacute;o thị trường, t&igrave;nh h&igrave;nh kinh doanh để hỗ trợ
						Ban Gi&aacute;m đốc trong việc ra quyết định.
					</li>
				</ul>
				<p className='font-bold'>7. Đề xuất cải tiến sản phẩm v&agrave; dịch vụ:</p>
				<ul>
					<li>Dựa tr&ecirc;n c&aacute;c phản hồi từ thị trường v&agrave; kh&aacute;ch h&agrave;ng, đề xuất c&aacute;c
						t&iacute;nh năng mới hoặc cải tiến sản phẩm hiện tại nhằm đ&aacute;p ứng nhu cầu kh&aacute;ch h&agrave;ng
						v&agrave; tạo lợi thế cạnh tranh.
					</li>
					<li>Phối hợp với đội ngũ ph&aacute;t triển sản phẩm v&agrave; ph&ograve;ng Kỹ thuật để triển khai c&aacute;c
						t&iacute;nh năng mới một c&aacute;ch hiệu quả.
					</li>
				</ul>
				<br/><h2 className='font-bold'>IV.QUYỀN HẠN:&nbsp;</h2>
				<ul>
					<li>Thực hiện được c&aacute;c c&ocirc;ng t&aacute;c chuy&ecirc;n m&ocirc;n nghiệp vụ theo đ&uacute;ng chức
						tr&aacute;ch
					</li>
					<li>Tham gia đ&oacute;ng g&oacute;p, x&acirc;y dựng kế hoạch ngắn hạn, trung hạn v&agrave; đề xuất c&aacute;c
						phương &aacute;n để quản l&yacute;, thực hiện c&ocirc;ng t&aacute;c thuộc phạm vi phụ tr&aacute;ch
						v&agrave; đ&aacute;nh gi&aacute; hiệu quả thực hiện
					</li>
					<li>Tổ chức, tham gia tổng hợp, ph&acirc;n t&iacute;ch đ&aacute;nh gi&aacute; c&ocirc;ng t&aacute;c
						chuy&ecirc;n m&ocirc;n, đề xuất chủ trương v&agrave; c&aacute;c biện ph&aacute;p n&acirc;ng cao hiệu quả.
					</li>
				</ul>
				<br/><h2 className='font-bold'>V.B&Aacute;O C&Aacute;O V&Agrave; UỶ QUYỀN</h2>
				<ol>
					<li>B&aacute;o c&aacute;o:&nbsp;</li>
				</ol>
				<ul>
					<li>Thực hiện b&aacute;o c&aacute;o định kỳ hoặc đột xuất theo y&ecirc;u cầu của Ban TGĐ về mọi th&ocirc;ng
						tin c&oacute; li&ecirc;n quan đến phạm vi tr&aacute;ch nhiệm được giao.
					</li>
				</ul>
				<ol start="2">
					<li>Ủy quyền:&nbsp;</li>
				</ol>
				<ul>
					<li>Kh&ocirc;ng</li>
				</ul>
				<br/><h2 className='font-bold'>VI.ĐIỀU KIỆN L&Agrave;M VIỆC&nbsp;</h2>
				<ul>
					<li>Thời gian l&agrave;m việc: Giờ h&agrave;nh ch&iacute;nh: Từ 8h00 đến 17h00</li>
				</ul>
				<ul>
					<li>Thời gian nghỉ h&agrave;ng tuần: Nghỉ chiều thứ 7 v&agrave; Ng&agrave;y Chủ nhật</li>
				</ul>
				<ul>
					<li>Trang thiết bị l&agrave;m việc được cung cấp (V&iacute; dụ: Laptop, PC, sim điện thoại,&hellip;): Laptop
					</li>
				</ul>
				<br/><h2 className='font-bold'>VII.TI&Ecirc;U CHUẨN TUYỂN DỤNG</h2>
				<p className='font-bold'>1. TR&Igrave;NH ĐỘ, KIẾN THỨC:</p>
				<ul>
					<li>Tr&igrave;nh độ học vấn tối thiểu: Cao đẳng</li>
					<li>Tr&igrave;nh độ chuy&ecirc;n m&ocirc;n: Tốt nghiệp Đại học/Cao đẳng c&aacute;c ng&agrave;nh Quản trị Kinh
						doanh, Marketing, Kinh tế, hoặc c&aacute;c lĩnh vực li&ecirc;n quan.
					</li>
					<li>Kiến thức li&ecirc;n quan: Am hiểu về fintech v&agrave; c&oacute; kiến thức s&acirc;u rộng về c&aacute;c
						giải ph&aacute;p c&ocirc;ng nghệ t&agrave;i ch&iacute;nh như v&iacute; điện tử, thanh to&aacute;n trực
						tuyến, v&agrave; c&aacute;c sản phẩm dịch vụ t&agrave;i ch&iacute;nh số l&agrave; một lợi thế.
					</li>
				</ul>
				<p className='font-bold'>2. KỸ NĂNG, KHẢ NĂNG:</p>
				<ul>
					<li>Ngoại ngữ: Giao tiếp lưu lo&aacute;t bằng tiếng Anh</li>
					<li>Tin học: Am hiểu v&agrave; sử dụng th&agrave;nh thạo tin học văn ph&ograve;ng</li>
					<li>Kỹ năng li&ecirc;n quan:&nbsp;</li>
					<ul>
						<li>Kỹ năng giao tiếp, đ&agrave;m ph&aacute;n v&agrave; thuyết phục tốt.</li>
						<li>Kỹ năng ph&acirc;n t&iacute;ch v&agrave; tổng hợp th&ocirc;ng tin thị trường, tư duy chiến lược tốt.
						</li>
						<li>Kỹ năng quản l&yacute; dự &aacute;n, lập kế hoạch v&agrave; tổ chức c&ocirc;ng việc hiệu quả.</li>
						<li>Sử dụng th&agrave;nh thạo c&aacute;c c&ocirc;ng cụ ph&acirc;n t&iacute;ch dữ liệu v&agrave; quản
							l&yacute; quan hệ kh&aacute;ch h&agrave;ng (CRM).
						</li>
					</ul>
				</ul>
				<ul>
					<li>Khả năng l&agrave;m việc c&aacute; nh&acirc;n:</li>
					<ul>
						<li>Trung thực, si&ecirc;ng năng, nhiệt t&igrave;nh, cẩn thận.</li>
						<li>C&oacute; tinh thần tr&aacute;ch nhiệm.</li>
						<li>C&oacute; khả năng ph&acirc;n t&iacute;ch v&agrave; xử l&yacute; vấn đề.</li>
						<li>Sẵn s&agrave;ng đi c&ocirc;ng t&aacute;c</li>
					</ul>
				</ul>
				<p className='font-bold'>3. Y&Ecirc;U CẦU KH&Aacute;C:</p>
				<ul>
					<li>Kinh nghiệm c&ocirc;ng t&aacute;c:</li>
					<ul>
						<li>C&oacute; &iacute;t nhất 2-3 năm kinh nghiệm trong lĩnh vực ph&aacute;t triển kinh doanh, đặc biệt
							l&agrave; trong c&aacute;c c&ocirc;ng ty t&agrave;i ch&iacute;nh, c&ocirc;ng nghệ hoặc v&iacute; điện tử
							l&agrave; lợi thế.
						</li>
					</ul>
				</ul>
				<ul>
					<li>Giới t&iacute;nh: Kh&ocirc;ng ph&acirc;n biệt</li>
					<li>Độ tuổi: Kh&ocirc;ng giới hạn</li>
				</ul>
				<br/><h1 className='font-bold text-[#F05A24]'>C&Aacute;CH THỨC ỨNG TUYỂN:</h1>
				<p>Ứng vi&ecirc;n nộp hồ sơ trực tuyến bằng c&aacute;ch gửi th&ocirc;ng tin CV đến địa chỉ email <a
					className='underline text-primary' href="mailto:tuyendung@goopay.vn">tuyendung@goopay.vn</a> .</p>
				<p><br/><br/><br/></p>
			</div>
		)
	},
	'chuyen-vien-phat-trien-san-pham': {
		title: 'CHUYÊN VIÊN PHÁT TRIỂN SẢN PHẨM (Product Development Specialist)',
		content: (
			<div className='px-8 md:px-[256px] py-8'>
				<br/><h1 className='font-bold text-xl text-[#F05A24]'>M&Ocirc; TẢ C&Ocirc;NG VIỆC</h1>
				<br/><h2 className='font-bold'>I.TH&Ocirc;NG TIN CHUNG:</h2>
				<ul>
					<li>Địa điểm l&agrave;m việc: Th&agrave;nh phố Hồ Ch&iacute; Minh</li>
					<li>Ph&ograve;ng: Ph&aacute;t Triển Sản Phẩm</li>
				</ul>
				<br/><h2 className='font-bold'>II.MỤC Đ&Iacute;CH:&nbsp;</h2>
				<ul>
					<li>Đ&oacute;ng vai tr&ograve; quan trọng trong việc định hướng v&agrave; ph&aacute;t triển c&aacute;c sản
						phẩm fintech chiến lược của c&ocirc;ng ty. Với trọng tr&aacute;ch quản l&yacute; to&agrave;n bộ v&ograve;ng
						đời sản phẩm v&agrave; đảm bảo sản phẩm đ&aacute;p ứng nhu cầu của thị trường, đ&acirc;y l&agrave; một vị
						tr&iacute; kh&ocirc;ng thể thiếu trong sự ph&aacute;t triển của c&ocirc;ng ty.
					</li>
				</ul>
				<br/><h2 className='font-bold'>III.TR&Aacute;CH NHIỆM:&nbsp;</h2>
				<p className='font-bold'>1. Nghi&ecirc;n cứu v&agrave; ph&acirc;n t&iacute;ch thị trường:</p>
				<ul>
					<li>Nghi&ecirc;n cứu thị trường, xu hướng c&ocirc;ng nghệ v&agrave; nhu cầu của kh&aacute;ch h&agrave;ng đối
						với c&aacute;c dịch vụ v&iacute; điện tử.
					</li>
					<li>Thu thập th&ocirc;ng tin về c&aacute;c đối thủ cạnh tranh, ph&acirc;n t&iacute;ch điểm mạnh, điểm yếu
						v&agrave; đề xuất c&aacute;c chiến lược ph&aacute;t triển sản phẩm ph&ugrave; hợp.
					</li>
					<li>X&aacute;c định nhu cầu của người d&ugrave;ng, khảo s&aacute;t &yacute; kiến v&agrave; ph&acirc;n
						t&iacute;ch h&agrave;nh vi kh&aacute;ch h&agrave;ng để tối ưu h&oacute;a t&iacute;nh năng của sản phẩm.
					</li>
				</ul>
				<p className='font-bold'>2. Ph&aacute;t triển v&agrave; quản l&yacute; sản phẩm:</p>
				<ul>
					<li>Đề xuất &yacute; tưởng, ph&aacute;t triển c&aacute;c t&iacute;nh năng v&agrave; dịch vụ mới cho
						v&iacute; điện tử dựa tr&ecirc;n nhu cầu thị trường v&agrave; xu hướng c&ocirc;ng nghệ.
					</li>
					<li>Tham gia lập kế hoạch ph&aacute;t triển sản phẩm, phối hợp với c&aacute;c bộ phận li&ecirc;n quan để đảm
						bảo tiến độ v&agrave; chất lượng sản phẩm.
					</li>
					<li>Theo d&otilde;i v&agrave; kiểm so&aacute;t qu&aacute; tr&igrave;nh ph&aacute;t triển sản phẩm, từ giai
						đoạn l&ecirc;n &yacute; tưởng đến triển khai v&agrave; ra mắt tr&ecirc;n thị trường.
					</li>
					<li>Li&ecirc;n tục cập nhật, cải tiến v&agrave; tối ưu h&oacute;a sản phẩm dựa tr&ecirc;n phản hồi của người
						d&ugrave;ng v&agrave; kết quả vận h&agrave;nh thực tế.
					</li>
				</ul>
				<p className='font-bold'>3. X&acirc;y dựng lộ tr&igrave;nh ph&aacute;t triển sản phẩm:</p>
				<ul>
					<li>X&acirc;y dựng v&agrave; theo d&otilde;i roadmap (lộ tr&igrave;nh ph&aacute;t triển) của sản phẩm trong
						ngắn hạn v&agrave; d&agrave;i hạn, đảm bảo c&aacute;c mục ti&ecirc;u ph&aacute;t triển được ho&agrave;n
						th&agrave;nh đ&uacute;ng thời gian.
					</li>
					<li>Phối hợp chặt chẽ với c&aacute;c ph&ograve;ng ban như Kỹ thuật, Marketing, Kinh doanh để đảm bảo
						c&aacute;c t&iacute;nh năng v&agrave; sản phẩm mới được ph&aacute;t triển đồng bộ v&agrave; mang lại
						gi&aacute; trị thực tiễn cho người d&ugrave;ng.
					</li>
				</ul>
				<p className='font-bold'>4. Quản l&yacute; v&ograve;ng đời sản phẩm:</p>
				<ul>
					<li>Theo d&otilde;i v&agrave; quản l&yacute; to&agrave;n bộ v&ograve;ng đời sản phẩm, từ giai đoạn ra mắt,
						ph&aacute;t triển đến duy tr&igrave; v&agrave; cải tiến.
					</li>
					<li>Đảm bảo sản phẩm lu&ocirc;n đạt hiệu suất tối ưu, đ&aacute;p ứng tốt c&aacute;c y&ecirc;u cầu của
						kh&aacute;ch h&agrave;ng v&agrave; ph&ugrave; hợp với chiến lược của c&ocirc;ng ty.
					</li>
					<li>Lập c&aacute;c b&aacute;o c&aacute;o ph&acirc;n t&iacute;ch về hiệu quả sản phẩm, đ&aacute;nh
						gi&aacute; c&aacute;c chỉ số hiệu suất (KPIs) để đưa ra c&aacute;c đề xuất điều chỉnh kịp thời.
					</li>
				</ul>
				<p className='font-bold'>5. Phối hợp với bộ phận Kỹ thuật v&agrave; Thiết kế:</p>
				<ul>
					<li>Phối hợp với bộ phận Kỹ thuật để ph&aacute;t triển v&agrave; kiểm thử c&aacute;c t&iacute;nh năng mới của
						sản phẩm, đảm bảo chất lượng v&agrave; hiệu quả sử dụng.
					</li>
					<li>L&agrave;m việc với đội ngũ Thiết kế để đảm bảo giao diện v&agrave; trải nghiệm người d&ugrave;ng (UI/UX)
						của sản phẩm th&acirc;n thiện, dễ sử dụng v&agrave; ph&ugrave; hợp với nhu cầu của kh&aacute;ch h&agrave;ng.
					</li>
					<li>Đảm bảo sản phẩm được ph&aacute;t triển theo đ&uacute;ng ti&ecirc;u chuẩn kỹ thuật v&agrave; thẩm mỹ
						m&agrave; c&ocirc;ng ty đ&atilde; đặt ra.
					</li>
				</ul>
				<p className='font-bold'>6. Ph&acirc;n t&iacute;ch v&agrave; b&aacute;o c&aacute;o kết quả sản phẩm:</p>
				<ul>
					<li>Thu thập v&agrave; ph&acirc;n t&iacute;ch dữ liệu li&ecirc;n quan đến sản phẩm như tỷ lệ sử dụng, độ
						h&agrave;i l&ograve;ng của kh&aacute;ch h&agrave;ng, lỗi ph&aacute;t sinh, v&agrave; c&aacute;c vấn đề
						li&ecirc;n quan đến vận h&agrave;nh.
					</li>
					<li>Đưa ra c&aacute;c b&aacute;o c&aacute;o định kỳ về t&igrave;nh h&igrave;nh ph&aacute;t triển sản phẩm, đề
						xuất c&aacute;c biện ph&aacute;p cải thiện v&agrave; ph&aacute;t triển th&ecirc;m c&aacute;c t&iacute;nh
						năng mới.
					</li>
					<li>Theo d&otilde;i phản hồi từ người d&ugrave;ng v&agrave; điều chỉnh sản phẩm để ph&ugrave; hợp với
						y&ecirc;u cầu thực tế v&agrave; n&acirc;ng cao trải nghiệm người d&ugrave;ng.
					</li>
				</ul>
				<p className='font-bold'>7. Hỗ trợ c&aacute;c chiến dịch marketing v&agrave; kinh doanh:</p>
				<ul>
					<li>Phối hợp với ph&ograve;ng Marketing để ph&aacute;t triển c&aacute;c chương tr&igrave;nh quảng
						b&aacute; sản phẩm, gi&uacute;p người d&ugrave;ng hiểu r&otilde; hơn về t&iacute;nh năng
						v&agrave; lợi &iacute;ch của v&iacute; điện tử.
					</li>
					<li>Hỗ trợ ph&ograve;ng Kinh doanh trong việc ph&acirc;n t&iacute;ch nhu cầu kh&aacute;ch h&agrave;ng,
						l&ecirc;n kế hoạch ph&aacute;t triển sản phẩm dựa tr&ecirc;n chiến lược kinh doanh của c&ocirc;ng ty.
					</li>
				</ul>
				<br/><h2 className='font-bold'>IV.QUYỀN HẠN:&nbsp;</h2>
				<ul>
					<li>Thực hiện được c&aacute;c c&ocirc;ng t&aacute;c chuy&ecirc;n m&ocirc;n nghiệp vụ theo đ&uacute;ng chức
						tr&aacute;ch
					</li>
					<li>Tham gia đ&oacute;ng g&oacute;p, x&acirc;y dựng kế hoạch ngắn hạn, trung hạn v&agrave; đề xuất c&aacute;c
						phương &aacute;n để quản l&yacute;, thực hiện c&ocirc;ng t&aacute;c thuộc phạm vi phụ tr&aacute;ch
						v&agrave; đ&aacute;nh gi&aacute; hiệu quả thực hiện
					</li>
					<li>Tổ chức, tham gia tổng hợp, ph&acirc;n t&iacute;ch đ&aacute;nh gi&aacute; c&ocirc;ng t&aacute;c
						chuy&ecirc;n m&ocirc;n, đề xuất chủ trương v&agrave; c&aacute;c biện ph&aacute;p n&acirc;ng cao hiệu quả.
					</li>
				</ul>
				<br/><h2 className='font-bold'>V.B&Aacute;O C&Aacute;O V&Agrave; UỶ QUYỀN</h2>
				<ol>
					<li>B&aacute;o c&aacute;o:&nbsp;</li>
				</ol>
				<ul>
					<li>Thực hiện b&aacute;o c&aacute;o định kỳ hoặc đột xuất theo y&ecirc;u cầu của Ban TGĐ về mọi th&ocirc;ng
						tin c&oacute; li&ecirc;n quan đến phạm vi tr&aacute;ch nhiệm được giao.
					</li>
				</ul>
				<ol start="2">
					<li>Ủy quyền:&nbsp;</li>
				</ol>
				<ul>
					<li>Kh&ocirc;ng</li>
				</ul>
				<br/><h2 className='font-bold'>VI.ĐIỀU KIỆN L&Agrave;M VIỆC&nbsp;</h2>
				<ul>
					<li>Thời gian l&agrave;m việc: Giờ h&agrave;nh ch&iacute;nh: Từ 8h00 đến 17h00</li>
				</ul>
				<ul>
					<li>Thời gian nghỉ h&agrave;ng tuần: Nghỉ chiều thứ 7 v&agrave; Ng&agrave;y Chủ nhật</li>
				</ul>
				<ul>
					<li>Trang thiết bị l&agrave;m việc được cung cấp (V&iacute; dụ: Laptop, PC, sim điện thoại,&hellip;): Laptop
					</li>
				</ul>
				<br/><h2 className='font-bold'>VII.TI&Ecirc;U CHUẨN TUYỂN DỤNG</h2>
				<p className='font-bold'>1. TR&Igrave;NH ĐỘ, KIẾN THỨC:</p>
				<ul>
					<li>Tr&igrave;nh độ học vấn tối thiểu: Đại học</li>
					<li>Tr&igrave;nh độ chuy&ecirc;n m&ocirc;n: Tốt nghiệp Đại học c&aacute;c ng&agrave;nh Quản trị Kinh doanh,
						Marketing, Kinh tế, hoặc c&aacute;c lĩnh vực li&ecirc;n quan.
					</li>
					<li>Kiến thức li&ecirc;n quan: Hiểu biết s&acirc;u rộng về c&aacute;c sản phẩm v&agrave; dịch vụ trong
						ng&agrave;nh c&ocirc;ng nghệ t&agrave;i ch&iacute;nh, đặc biệt l&agrave; c&aacute;c sản phẩm như
						v&iacute; điện tử, thanh to&aacute;n trực tuyến, blockchain, v&agrave; c&aacute;c giải ph&aacute;p
						t&agrave;i ch&iacute;nh số.
					</li>
				</ul>
				<p className='font-bold'>2. KỸ NĂNG, KHẢ NĂNG:</p>
				<ul>
					<li>Ngoại ngữ: Giao tiếp lưu lo&aacute;t bằng tiếng Anh</li>
					<li>Tin học: Am hiểu v&agrave; sử dụng th&agrave;nh thạo tin học văn ph&ograve;ng</li>
					<li>Kỹ năng li&ecirc;n quan:&nbsp;</li>
					<ul>
						<li>Kỹ năng quản l&yacute; dự &aacute;n, lập kế hoạch v&agrave; tổ chức c&ocirc;ng việc tốt.</li>
						<li>Khả năng tư duy s&aacute;ng tạo, ph&acirc;n t&iacute;ch v&agrave; tổng hợp th&ocirc;ng tin tốt.</li>
						<li>Th&agrave;nh thạo c&aacute;c c&ocirc;ng cụ ph&acirc;n t&iacute;ch dữ liệu, l&agrave;m việc với
							c&aacute;c phần mềm quản l&yacute; sản phẩm như Jira, Trello, Asana.
						</li>
						<li>Kỹ năng giao tiếp tốt, khả năng l&agrave;m việc độc lập cũng như l&agrave;m việc nh&oacute;m hiệu quả.
						</li>
					</ul>
				</ul>
				<ul>
					<li>Khả năng l&agrave;m việc c&aacute; nh&acirc;n:</li>
					<ul>
						<li>Trung thực, si&ecirc;ng năng, nhiệt t&igrave;nh, cẩn thận.</li>
						<li>C&oacute; tinh thần tr&aacute;ch nhiệm.</li>
						<li>C&oacute; khả năng ph&acirc;n t&iacute;ch v&agrave; xử l&yacute; vấn đề.</li>
						<li>Sẵn s&agrave;ng đi c&ocirc;ng t&aacute;c</li>
					</ul>
				</ul>
				<p className='font-bold'>3. Y&Ecirc;U CẦU KH&Aacute;C:</p>
				<ul>
					<li>Kinh nghiệm c&ocirc;ng t&aacute;c:</li>
					<ul>
						<li>&Iacute;t nhất 2-3 năm kinh nghiệm l&agrave;m việc trong lĩnh vực ph&aacute;t triển sản phẩm, đặc biệt
							trong c&aacute;c c&ocirc;ng ty t&agrave;i ch&iacute;nh, c&ocirc;ng nghệ, hoặc v&iacute; điện tử.
						</li>
						<li>C&oacute; kinh nghiệm về ph&aacute;t triển sản phẩm kỹ thuật số, ứng dụng di động, hoặc c&aacute;c dịch
							vụ fintech l&agrave; một lợi thế.
						</li>
					</ul>
				</ul>
				<ul>
					<li>Giới t&iacute;nh: Kh&ocirc;ng ph&acirc;n biệt</li>
					<li>Độ tuổi: Kh&ocirc;ng giới hạn</li>
				</ul>
				<br/><h1 className='font-bold text-[#F05A24]'>C&Aacute;CH THỨC ỨNG TUYỂN:</h1>
				<p>Ứng vi&ecirc;n nộp hồ sơ trực tuyến bằng c&aacute;ch gửi th&ocirc;ng tin CV đến địa chỉ email <a
					className='underline text-primary' href="mailto:tuyendung@goopay.vn">tuyendung@goopay.vn</a> .</p>
				<p><br/><br/><br/></p>
			</div>
		)
	},
	'truong-phong-marketing': {
		title: 'TRƯỞNG PHÒNG MARKETING (Marketing Manager)',
		content: (
			<div className='px-8 md:px-[256px] py-8'>
				<br/><h1 className='font-bold text-xl text-[#F05A24]'>M&Ocirc; TẢ C&Ocirc;NG VIỆC</h1>
				<br/><h2 className='font-bold'>I.TH&Ocirc;NG TIN CHUNG:</h2>
				<ul>
					<li>Địa điểm l&agrave;m việc: Th&agrave;nh phố Hồ Ch&iacute; Minh</li>
					<li>Ph&ograve;ng: Marketing</li>
				</ul>
				<br/><h2 className='font-bold'>II.MỤC Đ&Iacute;CH:&nbsp;</h2>
				<ul>
					<li>Điều h&agrave;nh Ph&ograve;ng Marketing theo sự ph&acirc;n c&ocirc;ng của Cấp Quản l&yacute; trực tiếp;
					</li>
					<li>Đ&oacute;ng vai tr&ograve; quyết định trong việc x&acirc;y dựng h&igrave;nh ảnh thương hiệu, mở rộng cơ sở
						kh&aacute;ch h&agrave;ng v&agrave; đưa sản phẩm fintech của c&ocirc;ng ty ra thị trường một c&aacute;ch mạnh
						mẽ. Người đảm nhận vai tr&ograve; n&agrave;y sẽ chịu tr&aacute;ch nhiệm dẫn dắt to&agrave;n bộ chiến lược
						marketing v&agrave; đ&oacute;ng g&oacute;p v&agrave;o sự ph&aacute;t triển bền vững của c&ocirc;ng ty.
					</li>
					<li>Chịu tr&aacute;ch nhiệm thực hiện c&aacute;c nhiệm vụ c&oacute; mức độ phức tạp cao.</li>
				</ul>
				<br/><h2 className='font-bold'>III.TR&Aacute;CH NHIỆM:&nbsp;</h2>
				<ol>
					<li>X&acirc;y dựng v&agrave; thực hiện chiến lược marketing tổng thể:</li>
				</ol>
				<ul>
					<li>Ph&aacute;t triển chiến lược marketing to&agrave;n diện: Thiết kế chiến lược marketing nhằm x&acirc;y dựng
						thương hiệu, tăng trưởng kh&aacute;ch h&agrave;ng, v&agrave; mở rộng thị trường cho c&aacute;c sản phẩm
						fintech của c&ocirc;ng ty như v&iacute; điện tử, dịch vụ thanh to&aacute;n số, v&agrave; c&aacute;c giải
						ph&aacute;p t&agrave;i ch&iacute;nh c&ocirc;ng nghệ.
					</li>
					<li>X&aacute;c định mục ti&ecirc;u marketing: Thiết lập c&aacute;c mục ti&ecirc;u ngắn hạn
						v&agrave; d&agrave;i hạn cho chiến dịch marketing dựa tr&ecirc;n kế hoạch kinh doanh của c&ocirc;ng ty.
					</li>
					<li>Thực hiện c&aacute;c chiến dịch marketing t&iacute;ch hợp: Quản l&yacute; v&agrave; triển khai c&aacute;c
						chiến dịch marketing qua nhiều k&ecirc;nh (trực tuyến v&agrave; ngoại tuyến) như SEO, SEM, mạng
						x&atilde; hội, quảng c&aacute;o trực tuyến, sự kiện, v&agrave; PR.
					</li>
				</ul>
				<ol start="2">
					<li>Quản l&yacute; c&aacute;c hoạt động marketing h&agrave;ng ng&agrave;y:</li>
				</ol>
				<ul>
					<li>Quản l&yacute; nội dung v&agrave; truyền th&ocirc;ng thương hiệu: Phối hợp với ph&ograve;ng Marketing Tập
						đo&agrave;n nhằm đảm bảo th&ocirc;ng điệp thương hiệu được truyền tải nhất qu&aacute;n qua c&aacute;c
						k&ecirc;nh truyền th&ocirc;ng, ph&aacute;t triển c&aacute;c chiến dịch nội dung s&aacute;ng tạo để thu
						h&uacute;t kh&aacute;ch h&agrave;ng.
					</li>
					<li>X&acirc;y dựng v&agrave; duy tr&igrave; thương hiệu: Ph&aacute;t triển chiến lược x&acirc;y dựng thương
						hiệu mạnh mẽ, đảm bảo thương hiệu fintech của c&ocirc;ng ty được nhận diện v&agrave; tin tưởng trong thị
						trường t&agrave;i ch&iacute;nh c&ocirc;ng nghệ.
					</li>
					<li>Gi&aacute;m s&aacute;t hoạt động quảng c&aacute;o: Phối hợp với C&ocirc;ng ty FUTA Advertising
						v&agrave; ph&ograve;ng Marketing Tập đo&agrave;n x&acirc;y dựng v&agrave; gi&aacute;m s&aacute;t việc thực
						hiện c&aacute;c chương tr&igrave;nh quảng c&aacute;o trực tuyến, ngoại tuyến, v&agrave; c&aacute;c hoạt động
						PR nhằm tối ưu h&oacute;a hiệu quả chi ph&iacute; v&agrave; đạt được mục ti&ecirc;u marketing.
					</li>
				</ul>
				<ol start="3">
					<li>Ph&acirc;n t&iacute;ch dữ liệu v&agrave; tối ưu h&oacute;a chiến dịch marketing:</li>
				</ol>
				<ul>
					<li>Ph&acirc;n t&iacute;ch hiệu suất chiến dịch: Sử dụng c&aacute;c c&ocirc;ng cụ ph&acirc;n t&iacute;ch
						marketing để theo d&otilde;i hiệu quả của c&aacute;c chiến dịch v&agrave; đo lường kết quả (ROI, tỷ lệ
						chuyển đổi, lưu lượng truy cập, v.v.).
					</li>
					<li>Tối ưu h&oacute;a chiến dịch marketing: Đưa ra c&aacute;c đề xuất điều chỉnh chiến lược v&agrave; kế hoạch
						marketing dựa tr&ecirc;n dữ liệu thực tế v&agrave; ph&acirc;n t&iacute;ch người d&ugrave;ng.
					</li>
					<li>B&aacute;o c&aacute;o hiệu quả: Cung cấp c&aacute;c b&aacute;o c&aacute;o định kỳ về hiệu quả chiến dịch
						marketing cho ban l&atilde;nh đạo, đồng thời đề xuất c&aacute;c phương &aacute;n cải tiến để tăng hiệu quả
						tiếp thị.
					</li>
				</ul>
				<ol start="4">
					<li>Quản l&yacute; đội ngũ marketing:</li>
				</ol>
				<ul>
					<li>X&acirc;y dựng v&agrave; l&atilde;nh đạo đội ngũ marketing: Tuyển dụng, quản
						l&yacute; v&agrave; ph&aacute;t triển đội ngũ marketing bao gồm c&aacute;c chuy&ecirc;n gia về nội dung,
						thiết kế, quảng c&aacute;o, v&agrave; PR.
					</li>
					<li>Ph&aacute;t triển kỹ năng nh&acirc;n sự: Đảm bảo đội ngũ được đ&agrave;o tạo b&agrave;i bản
						v&agrave; c&oacute; đủ kỹ năng để thực hiện c&aacute;c chiến dịch marketing phức tạp trong m&ocirc;i trường
						fintech cạnh tranh.
					</li>
					<li>Ph&acirc;n c&ocirc;ng c&ocirc;ng việc v&agrave; theo d&otilde;i hiệu suất: Ph&acirc;n bổ nhiệm vụ hợp
						l&yacute;, đ&aacute;nh gi&aacute; hiệu suất l&agrave;m việc của đội ngũ để đảm bảo c&aacute;c mục ti&ecirc;u
						marketing được ho&agrave;n th&agrave;nh đ&uacute;ng hạn.
					</li>
				</ul>
				<ol start="5">
					<li>Phối hợp với c&aacute;c bộ phận kh&aacute;c trong c&ocirc;ng ty:</li>
				</ol>
				<ul>
					<li>L&agrave;m việc với bộ phận kinh doanh v&agrave; ph&aacute;t triển người d&ugrave;ng: Phối hợp chặt chẽ
						với c&aacute;c bộ phận kinh doanh, ph&aacute;t triển người d&ugrave;ng, v&agrave; sản phẩm để đảm bảo sự
						đồng bộ trong c&aacute;c chiến lược marketing v&agrave; ph&aacute;t triển kh&aacute;ch h&agrave;ng.
					</li>
					<li>Hợp t&aacute;c với bộ phận c&ocirc;ng nghệ: Phối hợp với đội ngũ ph&aacute;t triển sản phẩm
						v&agrave; c&ocirc;ng nghệ để giới thiệu c&aacute;c sản phẩm fintech mới ra thị trường v&agrave; tối ưu
						h&oacute;a trải nghiệm người d&ugrave;ng.
					</li>
					<li>Phối hợp với bộ phận ph&aacute;p l&yacute; v&agrave; tu&acirc;n thủ: Đảm bảo tất cả c&aacute;c chiến dịch
						marketing tu&acirc;n thủ quy định của ph&aacute;p luật v&agrave; ch&iacute;nh s&aacute;ch nội bộ về truyền
						th&ocirc;ng v&agrave; quảng c&aacute;o.
					</li>
					<li>Phối hợp với C&ocirc;ng ty FUTA Advertising v&agrave; ph&ograve;ng Marketing Tập đo&agrave;n trong
						c&aacute;c hoạt động nhằm đảm bảo đạt được mục ti&ecirc;u v&agrave; chiến lược quảng b&aacute; thương hiệu,
						ph&aacute;t triển kh&aacute;ch h&agrave;ng.
					</li>
				</ul>
				<ol start="6">
					<li>Quản l&yacute; ng&acirc;n s&aacute;ch marketing:</li>
				</ol>
				<ul>
					<li>Lập kế hoạch ng&acirc;n s&aacute;ch: Ph&aacute;t triển v&agrave; quản l&yacute; ng&acirc;n s&aacute;ch
						marketing cho từng chiến dịch v&agrave; to&agrave;n bộ năm t&agrave;i ch&iacute;nh, đảm bảo sử dụng
						ng&acirc;n s&aacute;ch hiệu quả.
					</li>
					<li>Ph&acirc;n bổ nguồn lực: Đảm bảo rằng c&aacute;c nguồn lực t&agrave;i ch&iacute;nh v&agrave; nh&acirc;n sự
						được ph&acirc;n bổ một c&aacute;ch hợp l&yacute; để đạt được hiệu quả cao nhất trong c&aacute;c chiến dịch
						marketing.
					</li>
				</ul>
				<ol start="7">
					<li>Theo d&otilde;i xu hướng v&agrave; đổi mới trong lĩnh vực fintech:</li>
				</ol>
				<ul>
					<li>Nghi&ecirc;n cứu thị trường: Nắm bắt xu hướng thị trường, h&agrave;nh vi kh&aacute;ch h&agrave;ng,
						v&agrave; c&aacute;c đối thủ cạnh tranh trong lĩnh vực fintech để tạo ra c&aacute;c chiến lược marketing
						ti&ecirc;n phong v&agrave; đột ph&aacute;.
					</li>
					<li>Đổi mới v&agrave; thử nghiệm: Lu&ocirc;n t&igrave;m kiếm v&agrave; thử nghiệm c&aacute;c k&ecirc;nh
						v&agrave; phương ph&aacute;p mới nhằm tăng cường khả năng tiếp cận kh&aacute;ch h&agrave;ng
						v&agrave; n&acirc;ng cao nhận diện thương hiệu.
					</li>
				</ul>
				<br/><h2 className='font-bold'>IV.QUYỀN HẠN:&nbsp;</h2>
				<ul>
					<li>Thực hiện được c&aacute;c c&ocirc;ng t&aacute;c chuy&ecirc;n m&ocirc;n nghiệp vụ theo đ&uacute;ng chức
						tr&aacute;ch
					</li>
					<li>Tham gia đ&oacute;ng g&oacute;p, x&acirc;y dựng chiến lược, kế hoạch ngắn hạn, trung hạn v&agrave; đề xuất
						c&aacute;c phương &aacute;n để quản l&yacute;, thực hiện c&ocirc;ng t&aacute;c thuộc phạm vi phụ
						tr&aacute;ch v&agrave; đ&aacute;nh gi&aacute; hiệu quả thực hiện
					</li>
					<li>Tổ chức, tham gia tổng hợp, ph&acirc;n t&iacute;ch đ&aacute;nh gi&aacute; c&ocirc;ng t&aacute;c
						chuy&ecirc;n m&ocirc;n, đề xuất chủ trương v&agrave; c&aacute;c biện ph&aacute;p n&acirc;ng cao hiệu quả.
					</li>
				</ul>
				<br/><h2 className='font-bold'>V.B&Aacute;O C&Aacute;O V&Agrave; UỶ QUYỀN</h2>
				<ol>
					<li>B&aacute;o c&aacute;o:&nbsp;</li>
				</ol>
				<ul>
					<li>Thực hiện b&aacute;o c&aacute;o định kỳ hoặc đột xuất theo y&ecirc;u cầu của Ban TGĐ về mọi th&ocirc;ng
						tin c&oacute; li&ecirc;n quan đến phạm vi tr&aacute;ch nhiệm được giao.
					</li>
				</ul>
				<ol start="2">
					<li>Ủy quyền:&nbsp;</li>
				</ol>
				<ul>
					<li>Kh&ocirc;ng</li>
				</ul>
				<br/><h2 className='font-bold'>VI.ĐIỀU KIỆN L&Agrave;M VIỆC&nbsp;</h2>
				<ul>
					<li>Thời gian l&agrave;m việc: Giờ h&agrave;nh ch&iacute;nh: Từ 8h00 đến 17h00</li>
				</ul>
				<ul>
					<li>Thời gian nghỉ h&agrave;ng tuần: Nghỉ chiều thứ 7 v&agrave; Ng&agrave;y Chủ nhật</li>
				</ul>
				<ul>
					<li>Trang thiết bị l&agrave;m việc được cung cấp (V&iacute; dụ: Laptop, PC, sim điện thoại,&hellip;): Laptop
					</li>
				</ul>
				<br/><h2 className='font-bold'>VII.TI&Ecirc;U CHUẨN TUYỂN DỤNG</h2>
				<p className='font-bold'>1. TR&Igrave;NH ĐỘ, KIẾN THỨC:</p>
				<ul>
					<li>Tr&igrave;nh độ học vấn tối thiểu: Đại học</li>
					<li>Tr&igrave;nh độ chuy&ecirc;n m&ocirc;n: Cử nh&acirc;n hoặc Thạc sĩ c&aacute;c ng&agrave;nh Quản trị Kinh
						doanh, Marketing, Kinh tế, hoặc c&aacute;c lĩnh vực li&ecirc;n quan.
					</li>
					<li>Kiến thức li&ecirc;n quan: C&oacute; kiến thức chuy&ecirc;n s&acirc;u về c&aacute;c sản phẩm t&agrave;i
						ch&iacute;nh c&ocirc;ng nghệ như v&iacute; điện tử, thanh to&aacute;n trực tuyến, v&agrave; dịch vụ
						t&agrave;i ch&iacute;nh số.
					</li>
				</ul>
				<p className='font-bold'>2. KỸ NĂNG, KHẢ NĂNG:</p>
				<ul>
					<li>Ngoại ngữ: Giao tiếp lưu lo&aacute;t tiếng Anh</li>
					<li>Tin học: Th&agrave;nh thạo tin học Văn Ph&ograve;ng</li>
					<li>Kỹ năng li&ecirc;n quan:&nbsp;</li>
					<ul>
						<li>Kỹ năng quản l&yacute; dự &aacute;n: Kỹ năng l&atilde;nh đạo, quản l&yacute; dự &aacute;n,
							v&agrave; triển khai chiến dịch marketing từ kh&acirc;u lập kế hoạch đến thực thi.
						</li>
						<li>Kỹ năng ph&acirc;n t&iacute;ch: C&oacute; khả năng sử dụng c&aacute;c c&ocirc;ng cụ ph&acirc;n
							t&iacute;ch marketing như Google Analytics, Facebook Ads Manager, hoặc c&aacute;c c&ocirc;ng cụ tương tự
							để đo lường v&agrave; tối ưu h&oacute;a hiệu quả chiến dịch.
						</li>
						<li>Kỹ năng giao tiếp: Giao tiếp tốt, khả năng l&agrave;m việc với nhiều ph&ograve;ng ban kh&aacute;c nhau
							trong c&ocirc;ng ty v&agrave; x&acirc;y dựng quan hệ với đối t&aacute;c b&ecirc;n ngo&agrave;i.
						</li>
						<li>S&aacute;ng tạo v&agrave; đổi mới: Tư duy s&aacute;ng tạo, khả năng đổi mới v&agrave; th&iacute;ch ứng
							với c&aacute;c xu hướng mới trong ng&agrave;nh marketing v&agrave; fintech.
						</li>
					</ul>
				</ul>
				<ul>
					<li>Khả năng l&agrave;m việc c&aacute; nh&acirc;n:</li>
					<ul>
						<li>Trung thực, si&ecirc;ng năng, nhiệt t&igrave;nh, cẩn thận.</li>
						<li>C&oacute; tinh thần tr&aacute;ch nhiệm.</li>
						<li>C&oacute; khả năng ph&acirc;n t&iacute;ch v&agrave; xử l&yacute; vấn đề.</li>
						<li>Sẵn s&agrave;ng đi c&ocirc;ng t&aacute;c</li>
					</ul>
				</ul>
				<p className='font-bold'>3. Y&Ecirc;U CẦU KH&Aacute;C:</p>
				<ul>
					<li>Kinh nghiệm c&ocirc;ng t&aacute;c:</li>
					<ul>
						<li>Tối thiểu 5-7 năm kinh nghiệm trong lĩnh vực marketing, với &iacute;t nhất 2-3 năm ở vị tr&iacute; quản
							l&yacute; marketing trong lĩnh vực fintech, c&ocirc;ng nghệ hoặc thương mại điện tử.
						</li>
					</ul>
				</ul>
				<ul>
					<li>Giới t&iacute;nh: Kh&ocirc;ng ph&acirc;n biệt</li>
					<li>Độ tuổi: Kh&ocirc;ng giới hạn</li>
				</ul>
				<br/><h1 className='font-bold text-[#F05A24]'>C&Aacute;CH THỨC ỨNG TUYỂN:</h1>
				<p>Ứng vi&ecirc;n nộp hồ sơ trực tuyến bằng c&aacute;ch gửi th&ocirc;ng tin CV đến địa chỉ email <a
					className='underline text-primary' href="mailto:tuyendung@goopay.vn">tuyendung@goopay.vn</a> .</p>
				<p><br/><br/><br/></p>
			</div>
		)
	},
	'truong-phong-phat-trien-kinh-doanh': {
		title: 'TRƯỞNG PHÒNG PHÁT TRIỂN KINH DOANH (Sale Development Manager)',
		content: (
			<div className='px-8 md:px-[256px] py-8'>
				<br/><h1 className='font-bold text-[#F05A24]'><span >M&Ocirc; TẢ C&Ocirc;NG VIỆC</span></h1>
				<br/><h2 className='font-bold'><span >I.TH&Ocirc;NG TIN CHUNG:</span></h2>
				<ul>
					<li ><span
						>Địa điểm l&agrave;m việc: Th&agrave;nh phố Hồ Ch&iacute; Minh</span></li>
					<li ><span
						>Ph&ograve;ng:&nbsp; Ph&aacute;t Triển Kinh Doanh</span></li>
				</ul>
				<p>&nbsp;</p>
				<br/><h2 className='font-bold'><span >II.MỤC Đ&Iacute;CH:&nbsp;</span></h2>
				<p>&nbsp;</p>
				<ul>
					<li ><span >Đứng đầu v&agrave; điều h&agrave;nh Ph&ograve;ng Ph&aacute;t Triển Kinh doanh theo sự ph&acirc;n c&ocirc;ng của Ban TGĐ.</span>
					</li>
					<li ><span >Đ&oacute;ng vai tr&ograve; quan trọng trong việc mở rộng thị trường, x&acirc;y dựng mối quan hệ đối t&aacute;c chiến lược v&agrave; tạo ra c&aacute;c cơ hội kinh doanh mới cho c&ocirc;ng ty fintech. Đ&acirc;y l&agrave; vị tr&iacute; then chốt để th&uacute;c đẩy tăng trưởng v&agrave; ph&aacute;t triển bền vững cho doanh nghiệp trong ng&agrave;nh c&ocirc;ng nghệ t&agrave;i ch&iacute;nh đầy cạnh tranh.</span>
					</li>
					<li ><span >Chịu tr&aacute;ch nhiệm thực hiện c&aacute;c nhiệm vụ c&oacute; mức độ phức tạp cao.</span>
					</li>
				</ul>
				<br/><h2 className='font-bold'><span >III.TR&Aacute;CH NHIỆM:&nbsp;</span></h2>
				<p>&nbsp;</p>
				<ol>
					<li><span > X&acirc;y dựng chiến lược ph&aacute;t triển kinh doanh:</span></li>
				</ol>
				<ul>
					<li ><span >Lập kế hoạch chiến lược ph&aacute;t triển kinh doanh: Ph&aacute;t triển v&agrave; triển khai chiến lược kinh doanh d&agrave;i hạn để mở rộng thị phần, tăng trưởng doanh thu, v&agrave; gia tăng sức ảnh hưởng của c&ocirc;ng ty fintech tr&ecirc;n thị trường.</span>
					</li>
					<li ><span >Ph&acirc;n t&iacute;ch thị trường: Nghi&ecirc;n cứu thị trường fintech, xu hướng c&ocirc;ng nghệ t&agrave;i ch&iacute;nh, đối thủ cạnh tranh v&agrave; nhu cầu của kh&aacute;ch h&agrave;ng để đề xuất c&aacute;c chiến lược tăng trưởng mới.</span>
					</li>
					<li ><span >Định vị thị trường: X&aacute;c định c&aacute;c cơ hội hợp t&aacute;c, ph&aacute;t triển sản phẩm mới v&agrave; giải ph&aacute;p kinh doanh, nhằm tối ưu h&oacute;a tiềm năng ph&aacute;t triển v&agrave; đ&aacute;p ứng nhu cầu thị trường.</span>
					</li>
				</ul>
				<ol start="2">
					<li><span > Triển khai v&agrave; theo d&otilde;i c&aacute;c chiến dịch ph&aacute;t triển kinh doanh:</span>
					</li>
				</ol>
				<ul>
					<li ><span >Quản l&yacute; dự &aacute;n kinh doanh: Lập kế hoạch v&agrave; triển khai c&aacute;c dự &aacute;n kinh doanh mới, từ giai đoạn ph&aacute;t triển &yacute; tưởng cho đến khi thực hiện, bao gồm việc đo lường v&agrave; đ&aacute;nh gi&aacute; kết quả.</span>
					</li>
					<li ><span
						>Theo d&otilde;i c&aacute;c chỉ số kinh doanh: Gi&aacute;m s&aacute;t v&agrave; ph&acirc;n t&iacute;ch c&aacute;c chỉ số kinh doanh ch&iacute;nh (KPIs) như doanh thu, lợi nhuận, chi ph&iacute;, v&agrave; thị phần, để điều chỉnh chiến lược v&agrave; đưa ra c&aacute;c giải ph&aacute;p kịp thời.</span>
					</li>
					<li ><span >Ph&aacute;t triển sản phẩm v&agrave; dịch vụ mới: Phối hợp với c&aacute;c bộ phận sản phẩm v&agrave; kỹ thuật để ph&aacute;t triển, triển khai c&aacute;c giải ph&aacute;p mới nhằm đ&aacute;p ứng nhu cầu thị trường v&agrave; mở rộng danh mục dịch vụ của c&ocirc;ng ty.</span>
					</li>
				</ul>
				<ol start="3">
					<li><span
						> Ph&aacute;t triển mối quan hệ đối t&aacute;c v&agrave; kh&aacute;ch h&agrave;ng:</span>
					</li>
				</ol>
				<ul>
					<li ><span
						>T&igrave;m kiếm đối t&aacute;c chiến lược: X&acirc;y dựng v&agrave; duy tr&igrave; mối quan hệ với c&aacute;c đối t&aacute;c chiến lược, bao gồm c&aacute;c ng&acirc;n h&agrave;ng, tổ chức t&agrave;i ch&iacute;nh, c&aacute;c nh&agrave; cung cấp dịch vụ fintech, v&agrave; c&aacute;c đối t&aacute;c c&ocirc;ng nghệ kh&aacute;c.</span>
					</li>
					<li ><span
						>Mở rộng cơ sở kh&aacute;ch h&agrave;ng: T&igrave;m kiếm v&agrave; ph&aacute;t triển mối quan hệ với c&aacute;c kh&aacute;ch h&agrave;ng tiềm năng, bao gồm cả doanh nghiệp v&agrave; kh&aacute;ch h&agrave;ng c&aacute; nh&acirc;n, nhằm tăng cường sử dụng sản phẩm v&agrave; dịch vụ của c&ocirc;ng ty.</span>
					</li>
					<li ><span
						>Đ&agrave;m ph&aacute;n hợp đồng: Thương thảo v&agrave; k&yacute; kết c&aacute;c hợp đồng hợp t&aacute;c với đối t&aacute;c, đảm bảo c&aacute;c điều khoản mang lại lợi &iacute;ch tốt nhất cho c&ocirc;ng ty.</span>
					</li>
				</ul>
				<ol start="4">
					<li><span > Quản l&yacute; v&agrave; gi&aacute;m s&aacute;t đội ngũ ph&aacute;t triển kinh doanh:</span>
					</li>
				</ol>
				<ul>
					<li ><span >L&atilde;nh đạo đội ngũ ph&aacute;t triển kinh doanh: Quản l&yacute; v&agrave; ph&aacute;t triển đội ngũ nh&acirc;n vi&ecirc;n thuộc ph&ograve;ng ph&aacute;t triển kinh doanh, đảm bảo hiệu suất l&agrave;m việc v&agrave; đạt được c&aacute;c chỉ ti&ecirc;u kinh doanh đề ra.</span>
					</li>
					<li ><span >Đ&agrave;o tạo v&agrave; ph&aacute;t triển kỹ năng: X&acirc;y dựng kế hoạch đ&agrave;o tạo v&agrave; n&acirc;ng cao kỹ năng cho đội ngũ nh&acirc;n vi&ecirc;n, đảm bảo họ nắm vững kiến thức về thị trường fintech v&agrave; c&aacute;c sản phẩm của c&ocirc;ng ty.</span>
					</li>
					<li ><span
						>Đ&aacute;nh gi&aacute; hiệu suất: Đ&aacute;nh gi&aacute; v&agrave; ph&acirc;n t&iacute;ch hiệu quả c&ocirc;ng việc của nh&acirc;n vi&ecirc;n, đưa ra c&aacute;c đề xuất v&agrave; chiến lược để cải thiện hiệu suất v&agrave; th&uacute;c đẩy tinh thần l&agrave;m việc.</span>
					</li>
				</ul>
				<ol start="5">
					<li><span > Đảm bảo sự h&agrave;i l&ograve;ng của kh&aacute;ch h&agrave;ng:</span>
					</li>
				</ol>
				<ul>
					<li ><span
						>X&acirc;y dựng chiến lược chăm s&oacute;c kh&aacute;ch h&agrave;ng: Phối hợp với c&aacute;c bộ phận li&ecirc;n quan để x&acirc;y dựng chiến lược chăm s&oacute;c kh&aacute;ch h&agrave;ng hiệu quả, đảm bảo sự h&agrave;i l&ograve;ng v&agrave; l&ograve;ng trung th&agrave;nh của kh&aacute;ch h&agrave;ng đối với sản phẩm v&agrave; dịch vụ của c&ocirc;ng ty.</span>
					</li>
					<li ><span >Thu thập phản hồi từ kh&aacute;ch h&agrave;ng: Theo d&otilde;i v&agrave; ph&acirc;n t&iacute;ch phản hồi của kh&aacute;ch h&agrave;ng, từ đ&oacute; đưa ra c&aacute;c đề xuất cải tiến sản phẩm, dịch vụ nhằm n&acirc;ng cao trải nghiệm người d&ugrave;ng.</span>
					</li>
				</ul>
				<ol start="6">
					<li><span
						> Quản l&yacute; ng&acirc;n s&aacute;ch ph&aacute;t triển kinh doanh:</span></li>
				</ol>
				<ul>
					<li ><span
						>Lập v&agrave; quản l&yacute; ng&acirc;n s&aacute;ch: X&acirc;y dựng v&agrave; quản l&yacute; ng&acirc;n s&aacute;ch ph&aacute;t triển kinh doanh, đảm bảo việc sử dụng t&agrave;i ch&iacute;nh hiệu quả v&agrave; tu&acirc;n thủ kế hoạch đề ra.</span>
					</li>
					<li ><span
						>Đ&aacute;nh gi&aacute; hiệu quả đầu tư: Theo d&otilde;i v&agrave; đ&aacute;nh gi&aacute; hiệu quả đầu tư v&agrave;o c&aacute;c dự &aacute;n kinh doanh, đảm bảo đạt được lợi &iacute;ch tối đa cho c&ocirc;ng ty từ c&aacute;c nguồn lực đ&atilde; đầu tư.</span>
					</li>
				</ul>
				<ol start="7">
					<li><span > B&aacute;o c&aacute;o v&agrave; tư vấn cho ban l&atilde;nh đạo:</span>
					</li>
				</ol>
				<ul>
					<li ><span >B&aacute;o c&aacute;o kết quả kinh doanh: Định kỳ b&aacute;o c&aacute;o kết quả kinh doanh v&agrave; hiệu quả c&aacute;c chiến dịch ph&aacute;t triển kinh doanh cho ban l&atilde;nh đạo, đề xuất c&aacute;c điều chỉnh cần thiết để tối ưu h&oacute;a kết quả.</span>
					</li>
					<li ><span >Tư vấn chiến lược: Đ&oacute;ng vai tr&ograve; tư vấn cho ban l&atilde;nh đạo về c&aacute;c cơ hội tăng trưởng, c&aacute;c th&aacute;ch thức thị trường v&agrave; c&aacute;c xu hướng c&ocirc;ng nghệ t&agrave;i ch&iacute;nh.</span>
					</li>
				</ul>
				<p>&nbsp;</p>
				<br/><h2 className='font-bold'><span >IV.QUYỀN HẠN:&nbsp;</span></h2>
				<ul>
					<li ><span >Thực hiện được c&aacute;c c&ocirc;ng t&aacute;c chuy&ecirc;n m&ocirc;n nghiệp vụ theo đ&uacute;ng chức tr&aacute;ch</span>
					</li>
					<li ><span >Tham gia đ&oacute;ng g&oacute;p, x&acirc;y dựng chiến lược, kế hoạch ngắn hạn, trung hạn v&agrave; đề xuất c&aacute;c phương &aacute;n để quản l&yacute;, thực hiện c&ocirc;ng t&aacute;c thuộc phạm vi phụ tr&aacute;ch v&agrave; đ&aacute;nh gi&aacute; hiệu quả thực hiện</span>
					</li>
					<li ><span
						>Tổ chức, tham gia tổng hợp, ph&acirc;n t&iacute;ch đ&aacute;nh gi&aacute; c&ocirc;ng t&aacute;c chuy&ecirc;n m&ocirc;n, đề xuất chủ trương v&agrave; c&aacute;c biện ph&aacute;p n&acirc;ng cao hiệu quả.</span>
					</li>
				</ul>
				<p>&nbsp;</p>
				<br/><h2 className='font-bold'><span >V.B&Aacute;O C&Aacute;O V&Agrave; UỶ QUYỀN</span></h2>
				<ol>
					<li><span > B&aacute;o c&aacute;o:&nbsp;</span></li>
				</ol>
				<ul>
					<li ><span >Thực hiện b&aacute;o c&aacute;o định kỳ hoặc đột xuất theo y&ecirc;u cầu của Ban TGĐ về mọi th&ocirc;ng tin c&oacute; li&ecirc;n quan đến phạm vi tr&aacute;ch nhiệm được giao.</span>
					</li>
				</ul>
				<p>&nbsp;</p>
				<ol start="2">
					<li><span > Ủy quyền:&nbsp;</span></li>
				</ol>
				<ul>
					<li ><span >Kh&ocirc;ng</span></li>
				</ul>
				<p>&nbsp;</p>
				<br/><h2 className='font-bold'><span >VI.ĐIỀU KIỆN L&Agrave;M VIỆC&nbsp;</span></h2>
				<ul>
					<li ><span
						>Thời gian l&agrave;m việc: Giờ h&agrave;nh ch&iacute;nh: Từ 8h00 đến 17h00</span>
					</li>
				</ul>
				<ul>
					<li ><span >Thời gian nghỉ h&agrave;ng tuần: Nghỉ chiều thứ 7 v&agrave; Ng&agrave;y Chủ nhật</span>
					</li>
				</ul>
				<ul>
					<li ><span
						>Trang thiết bị l&agrave;m việc được cung cấp (V&iacute; dụ: Laptop, PC, sim điện thoại,&hellip;): Laptop</span>
					</li>
				</ul>
				<br/><h2 className='font-bold'><span >VII.TI&Ecirc;U CHUẨN TUYỂN DỤNG</span></h2>
				<p><span >1.TR&Igrave;NH ĐỘ, KIẾN THỨC:</span></p>
				<p>&nbsp;</p>
				<ul>
					<li ><span
						>Tr&igrave;nh độ học vấn tối thiểu: Đại học</span></li>
					<li ><span
						>Tr&igrave;nh độ chuy&ecirc;n m&ocirc;n: Cử nh&acirc;n hoặc Thạc sĩ c&aacute;c ng&agrave;nh Quản trị Kinh doanh, T&agrave;i ch&iacute;nh, Kinh tế, hoặc c&aacute;c lĩnh vực li&ecirc;n quan.</span>
					</li>
					<li ><span
						>Kiến thức li&ecirc;n quan: Am hiểu về fintech v&agrave; c&oacute; kiến thức s&acirc;u rộng về c&aacute;c giải ph&aacute;p c&ocirc;ng nghệ t&agrave;i ch&iacute;nh như v&iacute; điện tử, thanh to&aacute;n trực tuyến, v&agrave; c&aacute;c sản phẩm dịch vụ t&agrave;i ch&iacute;nh số.</span>
					</li>
				</ul>
				<p>&nbsp;</p>
				<p><span >2.KỸ NĂNG, KHẢ NĂNG:</span></p>
				<ul>
					<li ><span >Ngoại ngữ: C&oacute; thể giao tiếp v&agrave; đọc hiểu t&agrave;i liệu chuy&ecirc;n ng&agrave;nh bằng tiếng Anh</span>
					</li>
					<li ><span >Tin học: Am hiểu v&agrave; sử dụng th&agrave;nh thạo c&aacute;c ứng dụng AI</span>
					</li>
					<li ><span >Kỹ năng li&ecirc;n quan:&nbsp;</span></li>
					<ul>
						<li ><span >Kỹ năng quản l&yacute;: Kỹ năng l&atilde;nh đạo, quản l&yacute; đội ngũ hiệu quả v&agrave; khả năng l&agrave;m việc độc lập cũng như phối hợp nh&oacute;m.</span>
						</li>
						<li ><span
							>Kỹ năng giao tiếp: Giao tiếp v&agrave; đ&agrave;m ph&aacute;n xuất sắc, khả năng x&acirc;y dựng mối quan hệ tốt với kh&aacute;ch h&agrave;ng, đối t&aacute;c v&agrave; c&aacute;c bộ phận li&ecirc;n quan.</span>
						</li>
						<li ><span
							>Kỹ năng ph&acirc;n t&iacute;ch: Khả năng ph&acirc;n t&iacute;ch thị trường, kh&aacute;ch h&agrave;ng, đối thủ cạnh tranh v&agrave; đưa ra c&aacute;c chiến lược ph&aacute;t triển ph&ugrave; hợp.</span>
						</li>
						<li ><span >Tinh thần đổi mới: Khả năng s&aacute;ng tạo, tư duy chiến lược v&agrave; tinh thần khởi nghiệp mạnh mẽ.</span>
						</li>
					</ul>
				</ul>
				<ul>
					<li ><span
						>Khả năng l&agrave;m việc c&aacute; nh&acirc;n:</span></li>
					<ul>
						<li ><span
							>Trung thực, si&ecirc;ng năng, nhiệt t&igrave;nh, cẩn thận.</span></li>
						<li ><span >C&oacute; tinh thần tr&aacute;ch nhiệm.</span>
						</li>
						<li ><span
							>C&oacute; khả năng ph&acirc;n t&iacute;ch v&agrave; xử l&yacute; vấn đề.</span>
						</li>
						<li ><span
							>Sẵn s&agrave;ng đi c&ocirc;ng t&aacute;c</span></li>
					</ul>
				</ul>
				<p>&nbsp;</p>
				<p><span >3.Y&Ecirc;U CẦU KH&Aacute;C:</span></p>
				<ul>
					<li ><span >Kinh nghiệm c&ocirc;ng t&aacute;c:</span></li>
					<ul>
						<li ><span >&Iacute;t nhất 7 năm kinh nghiệm trong lĩnh vực ph&aacute;t triển kinh doanh, trong đ&oacute; c&oacute; &iacute;t nhất 3 năm kinh nghiệm quản l&yacute; tại c&aacute;c c&ocirc;ng ty fintech hoặc t&agrave;i ch&iacute;nh.</span>
						</li>
					</ul>
				</ul>
				<ul>
					<li ><span
						>Giới t&iacute;nh: Kh&ocirc;ng ph&acirc;n biệt</span></li>
					<li ><span >Độ tuổi: Kh&ocirc;ng giới hạn</span></li>
				</ul>
				<br/><h1 className='font-bold text-[#F05A24]'><span >C&Aacute;CH THỨC ỨNG TUYỂN:</span></h1>
				<p><span >Ứng vi&ecirc;n nộp hồ sơ trực tuyến bằng c&aacute;ch gửi th&ocirc;ng tin CV đến địa chỉ email </span><a
					className='underline text-primary' href="mailto:tuyendung@goopay.vn"><span >tuyendung@goopay.vn</span></a><span
					> .</span></p>
				<p>&nbsp;</p>
				<p><br/><br/><br/></p>
			</div>
		)
	},
	'truong-phong-tai-chinh-ke-toan': {
		title: 'TRƯỞNG PHÒNG TÀI CHÍNH – KẾ TOÁN (Finance & Accounting Manager)',
		content: (
			<div className='px-8 md:px-[256px] py-8'>
				<br/><h1 className='font-bold text-xl text-[#F05A24]'>M&Ocirc; TẢ C&Ocirc;NG VIỆC</h1>
				<br/><h2 className='font-bold'>I.TH&Ocirc;NG TIN CHUNG:</h2>
				<ul>
					<li>Địa điểm l&agrave;m việc: Th&agrave;nh phố Hồ Ch&iacute; Minh</li>
					<li>Ph&ograve;ng: T&agrave;i ch&iacute;nh &ndash; Kế to&aacute;n</li>
				</ul>
				<br/><h2 className='font-bold'>II.MỤC Đ&Iacute;CH:&nbsp;</h2>
				<ul>
					<li>Đứng đầu v&agrave; điều h&agrave;nh Ph&ograve;ng T&agrave;i ch&iacute;nh &ndash; Kế to&aacute;n theo sự
						ph&acirc;n c&ocirc;ng của cấp Quản l&yacute; trực tiếp;
					</li>
					<li>Quản l&yacute; hệ thống t&agrave;i ch&iacute;nh, lập kế hoạch v&agrave; kiểm so&aacute;t chi ph&iacute;,
						vị tr&iacute; n&agrave;y đ&oacute;ng g&oacute;p lớn v&agrave;o việc x&acirc;y dựng nền tảng t&agrave;i
						ch&iacute;nh vững chắc v&agrave; hỗ trợ c&aacute;c quyết định chiến lược của ban l&atilde;nh đạo;
					</li>
					<li>Chịu tr&aacute;ch nhiệm thực hiện c&aacute;c nhiệm vụ c&oacute; mức độ phức tạp cao.</li>
				</ul>
				<br/><h2 className='font-bold'>III.TR&Aacute;CH NHIỆM:&nbsp;</h2>
				<ol>
					<li>Quản l&yacute; hoạt động t&agrave;i ch&iacute;nh - kế to&aacute;n của c&ocirc;ng ty:</li>
				</ol>
				<ul>
					<li>X&acirc;y dựng v&agrave; quản l&yacute; hệ thống t&agrave;i ch&iacute;nh - kế to&aacute;n: Thiết lập
						v&agrave; quản l&yacute; c&aacute;c quy tr&igrave;nh, ch&iacute;nh s&aacute;ch t&agrave;i ch&iacute;nh - kế
						to&aacute;n ph&ugrave; hợp với quy định ph&aacute;p luật v&agrave; chiến lược ph&aacute;t triển của
						c&ocirc;ng ty.
					</li>
					<li>Lập kế hoạch t&agrave;i ch&iacute;nh: X&acirc;y dựng kế hoạch t&agrave;i ch&iacute;nh ngắn hạn
						v&agrave; d&agrave;i hạn, bao gồm dự b&aacute;o d&ograve;ng tiền, ng&acirc;n s&aacute;ch
						v&agrave; c&aacute;c nguồn lực t&agrave;i ch&iacute;nh cần thiết cho c&aacute;c hoạt động kinh doanh.
					</li>
					<li>Quản l&yacute; d&ograve;ng tiền: Gi&aacute;m s&aacute;t d&ograve;ng tiền của c&ocirc;ng ty, đảm bảo thanh
						khoản v&agrave; khả năng thanh to&aacute;n, đồng thời tối ưu h&oacute;a việc sử dụng nguồn vốn.
					</li>
				</ul>
				<ol start="2">
					<li>Đảm bảo tu&acirc;n thủ v&agrave; b&aacute;o c&aacute;o t&agrave;i ch&iacute;nh:</li>
				</ol>
				<ul>
					<li>B&aacute;o c&aacute;o t&agrave;i ch&iacute;nh: Chuẩn bị c&aacute;c b&aacute;o c&aacute;o t&agrave;i
						ch&iacute;nh định kỳ (th&aacute;ng, qu&yacute;, năm), bao gồm bảng c&acirc;n đối kế to&aacute;n, b&aacute;o
						c&aacute;o kết quả hoạt động kinh doanh v&agrave; b&aacute;o c&aacute;o lưu chuyển tiền tệ, đảm bảo
						t&iacute;nh ch&iacute;nh x&aacute;c v&agrave; kịp thời.
					</li>
					<li>Đảm bảo tu&acirc;n thủ ph&aacute;p luật: Đảm bảo hệ thống t&agrave;i ch&iacute;nh - kế to&aacute;n của
						c&ocirc;ng ty tu&acirc;n thủ c&aacute;c quy định của ph&aacute;p luật về t&agrave;i ch&iacute;nh, kế
						to&aacute;n, thuế v&agrave; c&aacute;c quy định của cơ quan quản l&yacute; li&ecirc;n quan.
					</li>
					<li>Kiểm to&aacute;n: L&agrave;m việc với c&aacute;c đơn vị kiểm to&aacute;n độc lập để đảm bảo c&aacute;c
						b&aacute;o c&aacute;o t&agrave;i ch&iacute;nh được kiểm tra v&agrave; x&aacute;c nhận theo đ&uacute;ng quy
						tr&igrave;nh kiểm to&aacute;n chuẩn.
					</li>
				</ul>
				<ol start="3">
					<li>Quản l&yacute; ng&acirc;n s&aacute;ch v&agrave; kiểm so&aacute;t chi ph&iacute;:</li>
				</ol>
				<ul>
					<li>Lập v&agrave; gi&aacute;m s&aacute;t ng&acirc;n s&aacute;ch: X&acirc;y dựng v&agrave; quản
						l&yacute; ng&acirc;n s&aacute;ch của c&aacute;c ph&ograve;ng ban v&agrave; dự &aacute;n, đảm bảo nguồn lực
						t&agrave;i ch&iacute;nh được sử dụng hiệu quả v&agrave; tối ưu.
					</li>
					<li>Kiểm so&aacute;t chi ph&iacute;: Theo d&otilde;i v&agrave; kiểm so&aacute;t chi ph&iacute; hoạt động của
						c&ocirc;ng ty, đưa ra c&aacute;c đề xuất tiết kiệm chi ph&iacute; v&agrave; cải thiện hiệu suất t&agrave;i
						ch&iacute;nh.
					</li>
					<li>Đ&aacute;nh gi&aacute; v&agrave; ph&acirc;n t&iacute;ch t&agrave;i ch&iacute;nh: Đưa ra c&aacute;c
						b&aacute;o c&aacute;o ph&acirc;n t&iacute;ch t&agrave;i ch&iacute;nh nhằm hỗ trợ ban l&atilde;nh đạo trong
						việc ra quyết định chiến lược kinh doanh.
					</li>
				</ul>
				<ol start="4">
					<li>Quản l&yacute; thuế v&agrave; c&aacute;c vấn đề ph&aacute;p l&yacute; li&ecirc;n quan đến t&agrave;i
						ch&iacute;nh:
					</li>
				</ol>
				<ul>
					<li>Quản l&yacute; thuế: Đảm bảo việc k&ecirc; khai, nộp thuế v&agrave; c&aacute;c nghĩa vụ t&agrave;i
						ch&iacute;nh kh&aacute;c đ&uacute;ng hạn v&agrave; tu&acirc;n thủ quy định của cơ quan thuế.
					</li>
					<li>Tư vấn ph&aacute;p l&yacute; về t&agrave;i ch&iacute;nh: Phối hợp với bộ phận ph&aacute;p l&yacute; để đảm
						bảo c&aacute;c hoạt động t&agrave;i ch&iacute;nh của c&ocirc;ng ty tu&acirc;n thủ quy định ph&aacute;p luật,
						đồng thời tư vấn cho ban l&atilde;nh đạo về c&aacute;c vấn đề ph&aacute;p l&yacute; li&ecirc;n quan đến
						t&agrave;i ch&iacute;nh.
					</li>
				</ul>
				<ol start="5">
					<li>Quản l&yacute; quan hệ với c&aacute;c đối t&aacute;c t&agrave;i ch&iacute;nh:</li>
				</ol>
				<ul>
					<li>Quản l&yacute; c&aacute;c đối t&aacute;c ng&acirc;n h&agrave;ng: Thiết lập v&agrave; duy tr&igrave; mối
						quan hệ với c&aacute;c ng&acirc;n h&agrave;ng, tổ chức t&agrave;i ch&iacute;nh để quản l&yacute; c&aacute;c
						khoản vay, bảo l&atilde;nh v&agrave; c&aacute;c dịch vụ t&agrave;i ch&iacute;nh kh&aacute;c.
					</li>
					<li>T&igrave;m kiếm nguồn vốn: Phối hợp với c&aacute;c đối t&aacute;c t&agrave;i ch&iacute;nh để t&igrave;m
						kiếm c&aacute;c cơ hội huy động vốn, hỗ trợ t&agrave;i ch&iacute;nh cho c&aacute;c dự &aacute;n
						v&agrave; chiến lược ph&aacute;t triển của c&ocirc;ng ty.
					</li>
				</ul>
				<ol start="6">
					<li>Quản l&yacute; v&agrave; ph&aacute;t triển đội ngũ t&agrave;i ch&iacute;nh - kế to&aacute;n:</li>
				</ol>
				<ul>
					<li>Quản l&yacute; đội ngũ nh&acirc;n vi&ecirc;n: Gi&aacute;m s&aacute;t v&agrave; quản l&yacute; đội ngũ kế
						to&aacute;n, t&agrave;i ch&iacute;nh trong c&ocirc;ng ty, ph&acirc;n c&ocirc;ng nhiệm vụ, theo d&otilde;i
						hiệu suất v&agrave; hỗ trợ ph&aacute;t triển nghề nghiệp cho c&aacute;c th&agrave;nh vi&ecirc;n trong
						nh&oacute;m.
					</li>
					<li>Đ&agrave;o tạo v&agrave; ph&aacute;t triển nh&acirc;n vi&ecirc;n: Phối hợp với bộ phận nh&acirc;n sự để
						đ&agrave;o tạo v&agrave; n&acirc;ng cao kỹ năng chuy&ecirc;n m&ocirc;n cho đội ngũ t&agrave;i ch&iacute;nh -
						kế to&aacute;n, đảm bảo họ nắm vững c&aacute;c quy định ph&aacute;p luật v&agrave; xu hướng t&agrave;i
						ch&iacute;nh mới nhất.
					</li>
				</ul>
				<ol start="7">
					<li>Hỗ trợ chiến lược kinh doanh v&agrave; ph&aacute;t triển c&ocirc;ng ty:</li>
				</ol>
				<ul>
					<li>Tư vấn chiến lược t&agrave;i ch&iacute;nh: Tư vấn cho ban l&atilde;nh đạo về chiến lược t&agrave;i
						ch&iacute;nh, kế hoạch đầu tư v&agrave; ph&acirc;n bổ nguồn vốn, hỗ trợ c&aacute;c quyết định li&ecirc;n
						quan đến t&agrave;i ch&iacute;nh của c&ocirc;ng ty.
					</li>
					<li>Ph&acirc;n t&iacute;ch hiệu quả đầu tư: Đ&aacute;nh gi&aacute; hiệu quả của c&aacute;c dự &aacute;n đầu
						tư, từ đ&oacute; đưa ra c&aacute;c đề xuất v&agrave; khuyến nghị cải thiện.
					</li>
				</ul>
				<br/><h2 className='font-bold'>IV.QUYỀN HẠN:&nbsp;</h2>
				<ul>
					<li>Thực hiện được c&aacute;c c&ocirc;ng t&aacute;c chuy&ecirc;n m&ocirc;n nghiệp vụ theo đ&uacute;ng chức
						tr&aacute;ch
					</li>
					<li>Tham gia đ&oacute;ng g&oacute;p, x&acirc;y dựng chiến lược, kế hoạch ngắn hạn, trung hạn v&agrave; đề xuất
						c&aacute;c phương &aacute;n để quản l&yacute;, thực hiện c&ocirc;ng t&aacute;c thuộc phạm vi phụ
						tr&aacute;ch v&agrave; đ&aacute;nh gi&aacute; hiệu quả thực hiện
					</li>
					<li>Tổ chức, tham gia tổng hợp, ph&acirc;n t&iacute;ch đ&aacute;nh gi&aacute; c&ocirc;ng t&aacute;c
						chuy&ecirc;n m&ocirc;n, đề xuất chủ trương v&agrave; c&aacute;c biện ph&aacute;p n&acirc;ng cao hiệu quả.
					</li>
				</ul>
				<br/><h2 className='font-bold'>V.ĐIỀU KIỆN L&Agrave;M VIỆC&nbsp;</h2>
				<ul>
					<li>Thời gian l&agrave;m việc: Giờ h&agrave;nh ch&iacute;nh: Từ 8h00 đến 17h00</li>
				</ul>
				<ul>
					<li>Thời gian nghỉ h&agrave;ng tuần: Nghỉ chiều thứ 7 v&agrave; Ng&agrave;y Chủ nhật</li>
				</ul>
				<ul>
					<li>Trang thiết bị l&agrave;m việc được cung cấp (V&iacute; dụ: Laptop, PC, sim điện thoại,&hellip;): Laptop
					</li>
				</ul>
				<br/><h2 className='font-bold'>VI.TI&Ecirc;U CHUẨN TUYỂN DỤNG</h2>
				<p className='font-bold'>1. TR&Igrave;NH ĐỘ, KIẾN THỨC:</p>
				<ul>
					<li>Tr&igrave;nh độ học vấn tối thiểu: Đại học</li>
					<li>Tr&igrave;nh độ chuy&ecirc;n m&ocirc;n: Cử nh&acirc;n hoặc Thạc sĩ ng&agrave;nh T&agrave;i ch&iacute;nh,
						Kế to&aacute;n, Kiểm to&aacute;n hoặc c&aacute;c ng&agrave;nh li&ecirc;n quan.
					</li>
					<li>Kiến thức li&ecirc;n quan: Hiểu biết s&acirc;u về lĩnh vực c&ocirc;ng nghệ t&agrave;i ch&iacute;nh, đặc
						biệt l&agrave; c&aacute;c sản phẩm v&iacute; điện tử v&agrave; dịch vụ t&agrave;i ch&iacute;nh số
					</li>
				</ul>
				<p className='font-bold'>2. KỸ NĂNG, KHẢ NĂNG:</p>
				<ul>
					<li>Ngoại ngữ: C&oacute; thể đọc hiểu tiếng Anh chuy&ecirc;n ng&agrave;nh</li>
					<li>Tin học: Th&agrave;nh thạo tin học Văn Ph&ograve;ng</li>
					<li>Kỹ năng li&ecirc;n quan:&nbsp;</li>
					<ul>
						<li>Kỹ năng quản l&yacute;: C&oacute; khả năng quản l&yacute; đội ngũ t&agrave;i ch&iacute;nh - kế
							to&aacute;n, x&acirc;y dựng quy tr&igrave;nh v&agrave; tối ưu h&oacute;a hoạt động t&agrave;i
							ch&iacute;nh.
						</li>
						<li>Kỹ năng ph&acirc;n t&iacute;ch: Kỹ năng ph&acirc;n t&iacute;ch t&agrave;i ch&iacute;nh xuất sắc,
							c&oacute; khả năng đưa ra c&aacute;c quyết định dựa tr&ecirc;n dữ liệu t&agrave;i ch&iacute;nh cụ thể.
						</li>
						<li>Kỹ năng giao tiếp: Giao tiếp tốt, c&oacute; khả năng l&agrave;m việc với c&aacute;c đối t&aacute;c
							t&agrave;i ch&iacute;nh v&agrave; c&aacute;c đơn vị kiểm to&aacute;n.
						</li>
						<li>Kỹ năng tổ chức v&agrave; quản l&yacute; thời gian: Khả năng l&agrave;m việc dưới &aacute;p lực cao,
							quản l&yacute; tốt nhiều nhiệm vụ c&ugrave;ng l&uacute;c.
						</li>
						<li>Hiểu biết s&acirc;u về lĩnh vực c&ocirc;ng nghệ t&agrave;i ch&iacute;nh, đặc biệt l&agrave; c&aacute;c
							sản phẩm v&iacute; điện tử v&agrave; dịch vụ t&agrave;i ch&iacute;nh số
						</li>
					</ul>
				</ul>
				<ul>
					<li>Khả năng l&agrave;m việc c&aacute; nh&acirc;n:</li>
					<ul>
						<li>Ch&iacute;nh trực.</li>
						<li>C&oacute; tinh thần tr&aacute;ch nhiệm.</li>
						<li>Sẵng s&agrave;ng đi c&ocirc;ng t&aacute;c</li>
					</ul>
				</ul>
				<p className='font-bold'>3. Y&Ecirc;U CẦU KH&Aacute;C:</p>
				<ul>
					<li>Kinh nghiệm c&ocirc;ng t&aacute;c:</li>
					<ul>
						<li>&Iacute;t nhất 5-7 năm kinh nghiệm trong lĩnh vực t&agrave;i ch&iacute;nh - kế to&aacute;n, trong
							đ&oacute; c&oacute; &iacute;t nhất 3 năm kinh nghiệm quản l&yacute;, ưu ti&ecirc;n c&oacute; kinh nghiệm
							l&agrave;m việc trong c&aacute;c c&ocirc;ng ty fintech hoặc c&ocirc;ng nghệ t&agrave;i ch&iacute;nh.
						</li>
					</ul>
				</ul>
				<ul>
					<li>Giới t&iacute;nh: Kh&ocirc;ng ph&acirc;n biệt</li>
					<li>Độ tuổi: Kh&ocirc;ng giới hạn</li>
				</ul>
				<br/><h1 className='font-bold text-[#F05A24]'>C&Aacute;CH THỨC ỨNG TUYỂN:</h1>
				<p>Ứng vi&ecirc;n nộp hồ sơ trực tuyến bằng c&aacute;ch gửi th&ocirc;ng tin CV đến địa chỉ email <a
					className='underline text-primary' href="mailto:tuyendung@goopay.vn">tuyendung@goopay.vn</a> .</p>
				<p><br/><br/><br/></p>
			</div>
		)
	}
}

export const hiringData = [
	{
		title: 'CHUYÊN VIÊN ĐỐI SOÁT (Reconciliation Specialist)',
		quantity: 2,
		office: 'Thành phố Hồ Chí Minh',
		salary: 'Thoả thuận',
		expired: '31/03/2025',
		button: 'Xem chi tiết',
		slug: 'chuyen-vien-doi-soat'
	},
	{
		title: 'CHUYÊN VIÊN CHĂM SÓC KHÁCH HÀNG (Customer Service Specialist)',
		quantity: 3,
		office: 'Thành phố Hồ Chí Minh',
		salary: 'Thoả thuận',
		expired: '31/03/2025',
		button: 'Xem chi tiết',
		slug: 'chuyen-vien-cham-soc-khach-hang'
	}, {
		title: 'CHUYÊN VIÊN HÀNH CHÍNH NHÂN SỰ (HR & Admin Specialist)',
		quantity: 1,
		office: 'Thành phố Hồ Chí Minh',
		salary: 'Thoả thuận',
		expired: '31/03/2025',
		button: 'Xem chi tiết',
		slug: 'chuyen-vien-hanh-chinh-nhan-su'
	}, {
		title: 'CHUYÊN VIÊN KẾ TOÁN TỔNG HỢP (General Accounting Specialist)',
		quantity: 1,
		office: 'Thành phố Hồ Chí Minh',
		salary: 'Thoả thuận',
		expired: '31/03/2025',
		button: 'Xem chi tiết',
		slug: 'chuyen-vien-ke-toan-tong-hop'
	}, {
		title: 'CHUYÊN VIÊN PHÁT TRIỂN KINH DOANH (Sale Development Specialist)',
		quantity: 5,
		office: 'Thành phố Hồ Chí Minh',
		salary: 'Thoả thuận',
		expired: '31/03/2025',
		button: 'Xem chi tiết',
		slug: 'chuyen-vien-phat-trien-kinh-doanh'
	}, {
		title: 'CHUYÊN VIÊN PHÁT TRIỂN SẢN PHẨM (Product Development Specialist)',
		quantity: 1,
		office: 'Thành phố Hồ Chí Minh',
		salary: 'Thoả thuận',
		expired: '31/03/2025',
		button: 'Xem chi tiết',
		slug: 'chuyen-vien-phat-trien-san-pham'
	}, {
		title: 'TRƯỞNG PHÒNG MARKETING (Marketing Manager)',
		quantity: 1,
		office: 'Thành phố Hồ Chí Minh',
		salary: 'Thoả thuận',
		expired: '31/03/2025',
		button: 'Xem chi tiết',
		slug: 'truong-phong-marketing'
	}, {
		title: 'TRƯỞNG PHÒNG PHÁT TRIỂN KINH DOANH (Sale Development Manager)',
		quantity: 1,
		office: 'Thành phố Hồ Chí Minh',
		salary: 'Thoả thuận',
		expired: '31/03/2025',
		button: 'Xem chi tiết',
		slug: 'truong-phong-phat-trien-kinh-doanh'
	}, {
		title: 'TRƯỞNG PHÒNG TÀI CHÍNH – KẾ TOÁN (Finance & Accounting Manager)',
		quantity: 1,
		office: 'Thành phố Hồ Chí Minh',
		salary: 'Thoả thuận',
		expired: '31/03/2025',
		button: 'Xem chi tiết',
		slug: 'truong-phong-tai-chinh-ke-toan'
	}
]
