import React from 'react';
import Button from '../Common/Button';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import cn from 'classnames';
import './style.css';
import {PATH} from "../../constants";
import NewLink from "../NewLink/NewLink";

const BannerItem = ({
	                    image, imageMb, title, button, descriptions,
                    }) => {
	return (
		<div className="relative w-full h-[720px] overflow-hidden dynamic-size-div">
			<div
				className="hidden md:block absolute inset-0 bg-cover bg-center"
				style={{
					backgroundImage:
						`url(${image})`,
				}}
			/>
			<div
				className="md:hidden absolute inset-0 bg-cover bg-center"
				style={{
					backgroundImage:
						`url(${imageMb})`,
				}}
			/>
			<div className="relative mt-10 md:mt-40 ml-2 md:ml-32 z-10 flex flex-col h-full max-w-4xl mx-auto">
				<div className="mb-8">
					<img
						src={title}
						alt="Tropical beach paradise"
						className="h-20 md:h-32 rounded-lg"
					/>
				</div>
				{
					descriptions.items.map((desc, index) => (
						<p
							key={index}
							className={cn('flex items-center gap-2.5 ml-1 mt-3 font-bold sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-base lg:mx-0', descriptions.text)}>
							<img className="w-[10px]" src="/img/icon/rectangle.png" alt="rectangle"/>
							{desc}
						</p>
					))
				}
				<div className="mt-8 sm:mt-10 text-left">
					<NewLink to={button.to}>
						<Button variant={button.type} className={button.className}>
							{button.text}
						</Button>
					</NewLink>
				</div>
			</div>
		</div>
	);
};


const HomeBanner = () => {
	const data = [
		{
			id: 'main-banner-1',
			image: '/img/main-banner/main-banner-1.jpg',
			imageMb: '/img/main-banner/main-banner-1-mobile.jpg',
			title: '/img/main-banner/main-banner-1-text.png',
			button: {
				type: 'primary',
				className: 'font-bold uppercase',
				text: 'Trải nghiệm ngay',
				to: PATH.SERVICES + '#service-1',
			},
			descriptions: {
				text: 'text-black',
				items: ['Dễ dàng mua vé trực tiếp trong ứng dụng',
					'Mở bán vé Lễ/ Tết sớm',
					'Tích điểm thưởng cho từng chuyến đi'],
			},
		},
		{
			id: 'main-banner-2',
			image: '/img/main-banner/main-banner-2.jpg',
			imageMb: '/img/main-banner/main-banner-2-mobile.jpg',
			title: '/img/main-banner/main-banner-2-text.png',
			button: {
				className: 'bg-white text-primary font-bold uppercase',
				text: 'Trải nghiệm ngay',
				to: PATH.SERVICES + '#service-2',
			},
			descriptions: {
				text: 'text-white',
				items: [
					'Dễ dàng thao tác',
					'Thanh toán tiện lợi, mọi lúc mọi nơi',
					'Tích luỹ điểm thưởng cho từng đơn hàng',
				]
			},
		},
	];
	
	return (
		<div className='relative'>
			<Swiper
				className="mySwiper" pagination={true}
				loop={true}
				modules={[Autoplay, Pagination]}
				centeredSlides={true}
				autoplay={{
					delay: 5000,
					disableOnInteraction: true,
				}}
				speed={1000}
			>
				{
					(data || []).map((item, index) => (
						<SwiperSlide key={item.id}>
							<BannerItem {...item} />
						</SwiperSlide>
					))
				}
			</Swiper>
			<img className="absolute z-10 bottom-[-1px] w-full bg-cover bg-center" src="/img/ellipse.png" alt="ellipse"/>
		</div>
	);
};

export default HomeBanner;
