import React from 'react';
import FeatureRichList from '../Common/RichList/FeatureRichList';

const data = [
	{
		id: 0,
		title: 'Tại giao diện chính, chọn icon “Nạp / Rút tiền”',
	},
	{
		id: 1,
		title: 'Chọn “Nạp tiền vào ví”',
	},
	{id: 2, title: 'Nhập số tiền cần nạp và nhấn “Tiếp tục”',},
	{
		id: 3, title: 'Xác nhận thông tin nạp tiền.',
		subtitle: ['Bạn có thể thay đổi nguồn nạp tiền bằng cách chọn “Thay đổi” <br/>Nguồn tiền là các ngân hàng bạn đã liên kết trước đó']
	},
	{id: 4, title: 'Nhập mật khẩu thanh toán để xác nhận giao dịch'},
];

const TopUpContent = ({setStep, step, isSHB, expandMore}) => {
	return (
		<div className="w-full max-w-4xl py-4 px-2 md:px-4 mx-auto">
			<FeatureRichList data={data} step={step} isSHB={isSHB} setStep={setStep} expandMore={expandMore}/>
		</div>
	);
};

export default TopUpContent;
