import React, {useEffect, useRef, useState} from 'react';
import RegisterLoginContent from './RegisterLoginContent';
import LinkedBankContent from './LinkedBankContent';
import TopUpContent from './TopUpContent';
import PhoneSwiper from '../Common/PhoneSwiper';
import './style.css';
import EkycContent from "./EkycContent";
import CustomTabs from "../Common/CustomTabs";
import {PATH} from "../../constants";

const GuildTabs = () => {
	const [activeTab, setActiveTab] = useState(0);
	const [step, setStep] = useState(0);
	const guildRef = useRef(null);
	useEffect(() => {
		const hash = window.location.hash; // e.g., "#hdsd"
		if (hash === '#hdsd') {
			const element = guildRef.current;
			if (element) {
				const topPosition = element.getBoundingClientRect().top + window.pageYOffset;
				window.scrollTo({
					top: topPosition + 500,
				});
			}
		}
	}, []);
	
	const tabs = [
		{label: 'Đăng ký/ Đăng nhập', quantity: 5, content: <RegisterLoginContent setStep={setStep} step={step}/>},
		{label: 'Định danh điện tử', quantity: 5, content: <EkycContent setStep={setStep} step={step}/>},
		{
			label: 'Liên kết ngân hàng',
			quantity: 5,
			content: <LinkedBankContent setStep={setStep} isLinkedBank step={step} expandMore={
				{
					title: 'Xem thêm hướng dẫn liên kết ngân hàng SHB',
					path: PATH.LINK_GUIDE
				}
			}/>,
			
		},
		{
			label: 'Nạp tiền vào Ví', quantity: 5, content: <TopUpContent setStep={setStep} isSHB step={step} expandMore={
				{
					title: 'Xem thêm hướng dẫn nạp tiền vào Ví từ ngân hàng SHB',
					path: PATH.LINK_GUIDE
				}
			}/>,
		},
	];
	
	const onClickTab = (index) => {
		setActiveTab(index);
	}
	
	return (
		<>
			<div className="mx-auto mt-8 md:w-[990px] relative pb-8 gap-20" ref={guildRef}>
				<div className="mx-5 md:mx-0 md:w-[661px] max-w-4xl">
					<h2 className="text-2xl font-bold text-center mb-6 uppercase">Hướng dẫn sử dụng</h2>
					<CustomTabs
						data={tabs}
						onClickTab={onClickTab}
						activeTab={activeTab}
					/>
					<div
						className="relative md:static md:block bg-transparent px-[30px] pt-4 rounded-b-lg h-[750px] md:h-[450px]"
						role="tabpanel"
						id={`tabpanel-${activeTab}`}
						aria-labelledby={`tab-${activeTab}`}
					>
						<div className="md:absolute flex justify-center top-0 right-0">
							<PhoneSwiper quantity={tabs[activeTab]?.quantity} activeTab={activeTab} step={step} setStep={setStep}/>
						</div>
						{tabs[activeTab].content}
					</div>
				</div>
			</div>
			<img className="z-[-1] mt-[-100px] md:mt-[-200px] w-full" src="/img/bg-city.png" alt="bg-city"/>
		</>
	);
};

export default GuildTabs;
