import React from "react";


const CustomTabs = ({data, onClickTab, activeTab}) => {
	return (
		<div
			className="pl-2.5 flex flex-col sm:flex-row items-center gap-2.5 bg-[#F1F9FF] rounded-lg pt-2.5 shadow-md shadow-bottom mb-8">
			{data.map((tab, index) => (
				<button
					key={index}
					onClick={() => onClickTab(index)}
					className={`text-sm relative flex items-center justify-center py-3 px-6 md:px-3 transition-colors duration-300 ${
						activeTab === index
							? 'gradient-box text-white font-semibold'
							: 'text-gray-700'
					} ${index === 1 ? '' : ''} ${index === 0 ? 'sm:rounded-t-lg' : ''} ${
						index === data.length - 1 ? 'rounded-t-lg mb-2 md:mb-0' : ''
					} ${index !== data.length - 1 ? 'rounded-t-lg' : ''}`}
					aria-selected={activeTab === index}
					role="tab"
					aria-controls={`tabpanel-${index}`}
					tabIndex={activeTab === index ? 0 : -1}
				>
					{activeTab === index && <i className="absolute w-1 h-5 bg-[#01B54F] left-0 rounded-r-md"/>}
					{activeTab === index && <i className="absolute w-1 h-5 bg-[#F05A24] right-0 rounded-l-md"/>}
					{tab.label}
				</button>
			))}
		</div>
	)
}


export default CustomTabs;
