import React from 'react';
import FeatureRichList from '../Common/RichList/FeatureRichList';

const data = [
    {
        id: 0,
        title: 'Tại giao diện chính, chọn “Nạp / Rút tiền”',
    },
    {
        id: 1,
        title: 'Chọn “ Rút tiền về ngân hàng”',
    },
    {id: 2, title: 'Nhập số tiền cần rút và “Tiếp tục” ',},
    {
        id: 3, title: 'Chọn  “Ngân hàng nhận tiền” là SHB',
    },
    {id: 4, title: 'Nhập mã PIN'},
    {id: 5, title: 'Chọn “Xác nhận”'},
    {id: 6, title: 'Rút tiền thành công'},
];

const WithdrawSHB = ({setStep, step}) => {
    return (
      <FeatureRichList data={data} step={step} linkedPage setStep={setStep}/>
    );
};

export default WithdrawSHB;
