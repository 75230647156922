import React from 'react';
import FeatureRichList from '../Common/RichList/FeatureRichList';

const data = [
    {id: 0, title: 'Mở ứng dụng GooPay, chọn icon “Tài khoản”'},
    {id: 1, title: 'Chọn “Quản lý Ví/ Thẻ”'},
    {id: 2, title: 'Chọn “Thêm liên kết ngân hàng”'},
    {id: 3, title: 'Chọn “Ngân hàng liên kết” trong danh sách'},
    {id: 4, title: 'Nhập thông tin cần thiết và xác thực'},
];

const LinkedBankContent = ({setStep, isLinkedBank, step, expandMore}) => {
    return (
        <div className="w-full max-w-4xl py-4 px-2 md:px-4 mx-auto">
            <FeatureRichList data={data} step={step} setStep={setStep} isLinkedBank={isLinkedBank} expandMore={expandMore}/>
        </div>
    );
};

export default LinkedBankContent;
