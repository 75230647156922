import React, {useEffect, useMemo, useRef, useState} from 'react';
import cn from 'classnames';
import './style.css';
import {AnimatePresence, motion} from 'framer-motion';
import NewLink from "../../NewLink/NewLink";

const FeatureRichList = ({data, setStep, customClass, step, subClass, linkedPage, expandMore}) => {
	const [selectedItem, setSelectedItem] = useState(0);
	const listRef = useRef(null);
	
	const activeTab = useMemo(() => {
		return data.find((tab) => tab.id === selectedItem)
	}, [selectedItem, data]);
	useEffect(() => {
		setSelectedItem(step || 0)
	}, [step])
	
	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
				e.preventDefault();
				const currentIndex = selectedItem ? data.findIndex(item => item.id === selectedItem) : -1;
				const newIndex = e.key === 'ArrowDown'
					? (currentIndex + 1) % data.length
					: (currentIndex - 1 + data.length) % data.length;
				setSelectedItem(data[newIndex].id);
			}
		};
		
		listRef.current.addEventListener('keydown', handleKeyDown);
	}, [data, selectedItem]);
	
	const handleItemClick = (id) => {
		if (id === step) return;
		setStep && setStep(id);
	};
	
	return (
		<div className="bg-transparent rounded-lg md:w[600px]">
			<div className="md:hidden rounded-lg w-full flex flex-col justify-center items-center">
				<AnimatePresence mode="wait">
					<motion.div
						key={selectedItem}
						initial={{opacity: 0, y: 20}}
						animate={{opacity: 1, y: 0}}
						exit={{opacity: 0, y: -20}}
						transition={{duration: 0.3}}
						className="pb-4"
					>
						<div className="text-gray-700">
							
							<div className="text-sm font-medium text-gray-800">
								{activeTab?.title}
								{
									activeTab?.subtitle && (
										activeTab?.subtitle.map((sub, index) => {
											if (activeTab?.subtype === 'link') {
												return (
													<div>
														<a key={index} href='https://nfc-guides.kalapa.vn/' target='_blank'
														   className={subClass}
														   dangerouslySetInnerHTML={{__html: sub}} rel="noreferrer"/>
													</div>
												)
											}
											return (
												<p key={index} className={subClass || "dot-before ml-1"}
												   dangerouslySetInnerHTML={{__html: sub}}/>
											)
										})
									)
								}
							</div>
							{data.find((tab) => tab.id === selectedItem)?.content}
						</div>
					</motion.div>
				</AnimatePresence>
				<div
					className={cn("flex flex-row flex-wrap justify-center items-center gap-2 pb-2", linkedPage ? '' : 'absolute bottom-10')}>
					{data.map((tab) => (
						<button
							key={tab.id}
							onClick={() => handleItemClick(tab.id)}
							className={cn('text-sm relative w-[30px] h-[30px] rounded-full', selectedItem === tab.id ? 'bg-[#0073DD] text-white' : 'bg-gradient-custom text-[#0073DD]')}
							aria-label={`Tab ${tab.id}`}
							aria-selected={selectedItem === tab.id}
							role="tab"
						>
							<span
								className={cn('absolute inset-0 flex items-center justify-center text-sm font-semibold z-10', selectedItem === tab.id && 'text-white')}>{tab.id + 1}</span>
						</button>
					))}
				</div>
				{
					expandMore &&
					<div className='flex items-center space-x-2 text-sm font-normal text-[#0073DD] pl-2 cursor-pointer'>
						<NewLink to={expandMore.path}>
							<div>{expandMore.title}</div>
						</NewLink>
						<img src="/img/icon/expand_more.svg" alt="expand_more"/>
					</div>
				}
			</div>
			
			{/*for desktop*/}
			
			<ul ref={listRef}
			    className={cn(customClass, linkedPage ? 'space-y-2.5' : 'space-y-8', "hidden md:block md:w-[600px]")}
			    aria-label="Feature-Rich List">
				{data.map((item) => (
					<div
						key={item.id}
						onClick={() => handleItemClick(item.id)}
						className={`flex items-center rounded-lg cursor-pointer`}
						role="option"
						aria-selected={selectedItem === item.id}
						tabIndex="0"
					>
						<p
							className={cn('relative mr-4 w-[40px] h-[40px] rounded-full', selectedItem === item.id ? 'bg-[#0073DD] text-white' : 'bg-gradient-custom text-[#0073DD]')}>
							<span
								className={cn('text-sm font-bold absolute inset-0 flex items-center justify-center')}>
							  {item.id + 1}
							</span>
						</p>
						<div className="w-[90%] text-sm font-medium text-gray-800 whitespace-pre-line">
							{item.title}
							{
								item?.subtitle && (
									item?.subtitle.map((sub, index) => {
										if (item?.subtype === 'link') {
											return (
												<div className='flex items-center gap-2'>
													<a key={index} href='https://nfc-guides.kalapa.vn/' target='_blank'
													   className={cn(subClass, 'underline text-[#0073DD]')}
													   dangerouslySetInnerHTML={{__html: sub}} rel="noreferrer"/>
													<img className='w-[5] h-2.5' src="/img/icon/expand_more_24px.png" alt=""/>
												</div>
											)
										}
										return (
											<p key={index} className={subClass || "dot-before ml-1"}
											   dangerouslySetInnerHTML={{__html: sub}}/>
										)
									})
								)
							}
						</div>
					</div>
				))}
				{expandMore &&
					<div className='flex items-center space-x-2 text-sm font-normal text-[#0073DD] pl-2 cursor-pointer'>
						<NewLink to={expandMore.path}>
							<div>{expandMore.title}</div>
						</NewLink>
						<img src="/img/icon/expand_more.svg" alt="expand_more"/>
					</div>
				}
			</ul>
		</div>
	);
};

export default FeatureRichList;
