import React from 'react';
import FeatureRichList from '../Common/RichList/FeatureRichList';


const data = [
    {id: 0, title: 'Tại màn hình chính, chọn “Tài khoản” ở góc phải bên dưới màn hình'},
    {id: 1, title: 'Chọn “Quản lý Ví/ Thẻ”'},
    {id: 2, title: 'Chọn “Thêm liên kết ngân hàng”'},
    {id: 3, title: 'Chọn ngân hàng SHB'},
    {id: 4, title: 'Nhập thông tin in trên thẻ SHB của bạn'},
    {id: 5, title: 'Ghi nhớ mã giao dịch tại màn hình “Xác thực”'},
    {id: 6, title: 'Vào ứng dụng SHB SAHA, và chọn “Lấy mã OTP”'},
    {id: 7, title: 'Nhập mã PIN smart OTP, sau đó nhập mã giao dịch vừa ghi nhớ ở Bước số 6'},
    {id: 8, title: '“Sao chép” mã xác thực vừa được tạo'},
    {id: 9, title: 'Quay về ứng dụng Ví GooPay, nhập mã xác thực vừa sao chép'},
    {
        id: 10, title: 'Liên kết ngân hàng thành công. Bạn có thể bắt đầu nạp tiền vào ví GooPay',
    },
];

const LinkedSHB = ({setStep, step, isSHB}) => {
    return (
      <FeatureRichList data={data} step={step} linkedPage setStep={setStep}/>
    );
};

export default LinkedSHB;
