import React from 'react';
import FeatureRichList from '../Common/RichList/FeatureRichList';

const data = [
    {id: 0, title: 'Tại giao diện chính, chọn “Nạp / Rút tiền”'},
    {id: 1, title: 'Chọn “Nạp tiền vào ví”'},
    {id: 2, title: 'Nhập số tiền cần nạp và nhấn “Tiếp tục”'},
    {id: 3, title: 'Xác nhận thông tin nạp tiền.\n' +
          'Tại Nguồn nạp chọn Ngân hàng SHB'},
    {id: 4, title: 'Nhập mã PIN để xác nhận giao dịch'},
    {id: 5, title: 'Chọn “Xác nhận”'},
    {id: 6, title: 'Ghi nhớ mã giao dịch tại màn hình “Xác thực”'},
    {id: 7, title: 'Vào ứng dụng SHB SAHA, và chọn “Lấy mã OTP”'},
    {id: 8, title: 'Nhập mã PIN smart OTP của ứng dụng SHB SAHA, sau đó nhập mã giao dịch vừa ghi nhớ ở Bước số 7'},
    {id: 9, title: 'Sao chép mã xác thực vừa được tạo'},
    {
        id: 10, title: 'Quay về ứng dụng Ví GooPay, nhập mã xác thực vừa sao chép',
    },
    {
        id: 11, title: 'Nạp tiền thành công. Bạn có thể quay về trang chủ để kiểm tra số dư Ví.',
    },
];

const TopUpSHB = ({setStep, step}) => {
    return (
      <FeatureRichList data={data} step={step} linkedPage setStep={setStep}/>
    );
};

export default TopUpSHB;
