// src/AppRouter.js
import React from 'react';
import {BrowserRouter as Router, Route, Routes, useSearchParams} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Header from './components/Header/Header';
import {PATH} from './constants';
import Services from './pages/Services';
import Hiring from './pages/Hiring';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import FAQ from './pages/FAQ/FAQ';
import FAQInfo from "./pages/FAQ/FAQInfo";
import FAQAccount from "./pages/FAQ/FAQAccount";
import FAQTopUp from "./pages/FAQ/FAQTopUp";
import FAQTransfer from "./pages/FAQ/FAQTransfer";
import FAQIncident from "./pages/FAQ/FAQIncident";
import FAQSafetySecure from "./pages/FAQ/FAQSafetySecure";
import PolicyUsage from "./pages/FAQ/PolicyUsage";
import PolicyPrivacy from "./pages/FAQ/PolicyPrivacy";
import PolicyFutaBusline from "./pages/FAQ/PolicyFutaBusline";
import Job from "./pages/Job";
import LinkGuide from "./pages/Linkguide";
import Download from "./pages/FAQ/Download";
import Guild from "./pages/Guild";

const AppRouter = () => {
	return(
		<Router>
			<Content />
		</Router>
	);
}

const Content = () => {
	const [params] = useSearchParams();
	const inApp = params.get('inApp');
	return (
		<div className="font-default">
			{!inApp && <Header/>}
			<Routes>
				<Route path={PATH.HOME} element={<Home/>}/>
				<Route path={PATH.SERVICES} element={<Services/>}/>
				<Route path={PATH.ABOUT} element={<About/>}/>
				<Route path={PATH.HIRING} element={<Hiring/>}/>
				<Route path={`${PATH.HIRING}/:slug`} element={<Job/>}/>
				<Route path={PATH.CONTACT} element={<Contact/>}/>
				<Route path={PATH.GUILD} element={<Guild/>}/>
				<Route path={PATH.FAQ} element={<FAQ/>}/>
				<Route path={PATH.FAQ_INFO} element={<FAQInfo/>}/>
				<Route path={PATH.FAQ_ACCOUNT} element={<FAQAccount/>}/>
				<Route path={PATH.FAQ_TOP_UP} element={<FAQTopUp/>}/>
				<Route path={PATH.FAQ_TRANSFER} element={<FAQTransfer/>}/>
				<Route path={PATH.FAQ_INCIDENT} element={<FAQIncident/>}/>
				<Route path={PATH.FAQ_SAFETY_SECURE} element={<FAQSafetySecure/>}/>
				<Route path={PATH.POLICY_USAGE} element={<PolicyUsage/>}/>
				<Route path={PATH.POLICY_PRIVACY} element={<PolicyPrivacy/>}/>
				<Route path={PATH.POLICY_FUTA_BUSLINE} element={<PolicyFutaBusline/>}/>
				<Route path={PATH.LINK_GUIDE} element={<LinkGuide/>}/>
				<Route path={PATH.TOP_UP_GUIDE} element={<LinkGuide/>}/>
				<Route path={PATH.DOWNLOAD} element={<Download />}/>
			</Routes>
			{!inApp && <Footer/>}
		</div>
	)
}

export default AppRouter;
