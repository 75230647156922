import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {handleScrollToHash} from "../services/services";
import PhoneSwiper from "../components/Common/PhoneSwiper";
import CustomTabs from "../components/Common/CustomTabs";
import LinkedSHB from "../components/SHB/LinkedSHB";
import TopUpSHB from "../components/SHB/TopUpSHB";
import WithdrawSHB from "../components/SHB/WithdrawSHB";


const dataLinked = [
	{id: 1, title: 'Thẻ đã đăng kí dịch vụ Internet Banking tại ngân hàng SHB'},
	{id: 2, title: 'Số điện thoại ví phải trùng với số điện thoại đã đăng ký tại ngân hàng SHB'},
	{id: 3, title: 'Số CMND/ CCCD đăng kí với ví phải trùng với số CMND/ CCCD đã đăng kí tại ngân hàng SHB'},
	{id: 4, title: 'Số tài khoản/ số thẻ này đang không liên kết với tài khoản ví GooPay khác'},
]
const LinkGuide = () => {
	const [activeTab, setActiveTab] = useState(0);
	const [step, setStep] = useState(0);
	const location = useLocation();
	const {hash} = location || {};
	useEffect(() => {
		handleScrollToHash({sectionId: hash.substring(1)});
	}, [hash]);
	
	const tabs = [
		{label: 'Liên kết ngân hàng', quantity: 11, content: <LinkedSHB isSHB setStep={setStep} step={step}/>},
		{label: 'Nạp tiền vào Ví', quantity: 12, content: <TopUpSHB isSHB setStep={setStep} step={step}/>},
		{label: 'Rút tiền về ngân hàng', quantity: 7, content: <WithdrawSHB isSHB setStep={setStep} step={step}/>},
	];
	
	const onClickTab = (index) => {
		setActiveTab(index);
	}
	
	return (
		<div className="mx-auto w-full animate-fadeIn text-sm space-y-5 md:space-y-10 py-10 px-[30px]">
			<div className='flex justify-center items-center text-center space-x-4 text-sm md:text-[17px] font-bold'>
				<div>
					Hướng dẫn liên kết Ví GooPay với tài khoản Ngân hàng
				</div>
				<img src='/img/SHB.svg' alt='shb'/>
			</div>
			
			<div className="mx-auto w-fit">
				<CustomTabs
					data={tabs}
					onClickTab={onClickTab}
					activeTab={activeTab}
				/>
				<div
					className='flex flex-wrap md:font-normal justify-center items-center md:justify-start md: md:items-start font-bold md:space-x-14'>
					<div
						className="bg-transparent rounded-b-lg pb-4 md:pb-0"
						role="tabpanel"
						id={`tabpanel-${activeTab}`}
						aria-labelledby={`tab-${activeTab}`}
					>
						<PhoneSwiper
							path={'guild-step/linked-step'}
							activeTab={activeTab}
							step={step}
							setStep={setStep}
							setActiveTab={setActiveTab}
							quantity={tabs[activeTab]?.quantity}
						/>
					</div>
					{tabs[activeTab].content}
				</div>
				<div className='text-sm max-w-[800px] mt-4'>
					<div className='space-y-2'>
						<div className='text-left'>
							Lưu ý: Để liên kết thành công, thẻ phải đáp ứng các điều kiện dưới đây
						</div>
						<div className="text-left">
							{dataLinked.map((item, index) => (
								<div key={index}
								     className='flex items-start space-x-2 mb-2'>
									<div className='w-1 h-1 min-w-1 min-h-1 rounded-full bg-black mt-2'></div>
									<div>{item.title}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default LinkGuide;
